<template>
  <div class="reseller-list">
    <div class="results-content">
      <v-list
        color="transparent"
      >
      <v-list-item-group v-model="reseller">
        <v-list-item
          v-for="reseller in resellerList" 
          :key="reseller.name"
        >
          <v-list-item-content>
            <a @click="setActiveReseller(reseller)" href="#">{{ stateLink(reseller) }}</a>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ResellerList',
  data () {
    return {
      reseller: [],
      showProducts: false,
    }
  },
  computed: {
    ...mapGetters({
        stateList: 'stateList',
        state: 'selectedState',
        basic: 'basic',
        resellerList: 'resellerList',
        loadingStatus: 'loadingStatus',
    }),
  },
  methods: {
    setActiveReseller(reseller) {
      console.log('setReseller - resellerList ' + JSON.stringify(reseller) )
      this.showProducts = true;
      let basic = this.basic;
      let loadingStatus = 'productListType';
      this.$store.dispatch('SET_LOADING_STATUS', { loadingStatus: loadingStatus });
      this.$store.dispatch('LOAD_RESELLER_NAME', { reseller: reseller });
      this.$store.dispatch('LOAD_RESELLER_PROFILE', { reseller: reseller, basic: basic });
      this.$store.dispatch('LOAD_RESELLER_PRODUCTS', { resellerName: reseller.name });
      let orderStep = 1;
      this.$store.dispatch('LOAD_ORDER_STEP', { orderStep });
      this.$router.push({ path: '/reseller' });
    },
    
    stateLink(reseller) {
        var resellerLine = reseller.schoolName;
        if (reseller.schoolCity) {
          resellerLine = resellerLine + ', ' + reseller.schoolCity;
        }
        return resellerLine;
      }
  }
}
</script>

<style>

</style>