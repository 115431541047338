<template>
  <div class="loading-info">
    <v-row justify="center" align="center">
      <v-progress-circular 
        indeterminate
        color="#CB4727"
        size="100"
        :width="8"
        >
        Loading
      </v-progress-circular>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'LoadingInfo'
}
</script>

<style lang="sass">

</style>
