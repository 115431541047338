import schoolStructure from '../../data/schoolStructure.js';
import { fetchReseller, fetchResellerList, fetchResellerStateList } from "@/utils/resellerServices.js";

const state = {
  resellerName: '',
  resellerList: [],
  resellerProfile: schoolStructure,
  selectedState: [],
  studentList: [],
};

const mutations = {
  UPDATE_RESELLER_NAME: (state, { resellerName }) => {
    state.resellerName = resellerName;
  },
  UPDATE_RESELLER_PROFILE: (state, { reseller }) => {
    // console.log('Update R Profile ' + JSON.stringify(reseller));
    state.resellerProfile = reseller;
  },
  UPDATE_RESELLER_LIST: (state, { resellerList }) => {
    state.resellerList = resellerList;
  },
  UPDATE_SELECTED_STATE: (state, { selectedState }) => {
    state.selectedState = selectedState;
  },
  UPDATE_STUDENT_LIST: (state, { studentList }) => {
    state.studentList = studentList;
  },
  CLEAR_RESELLER: (state) => {
    state.resellerProfile = null;
    state.resellerName = '';
    // state.resellerProfile = schoolStart;
  },
  CLEAR_RESELLER_LIST: (state) => {
    state.resellerList = [];
  }
  // updateField,
};

const actions = {
  RESELLER_RESET: ({ commit }) => {
    commit('CLEAR_RESELLER');
  },

  RESELLER_LIST_RESET: ({ commit }) => {
    commit('CLEAR_RESELLER_LIST');
  },

  LOAD_RESELLER_NAME: ({ commit, state }, { reseller }) => {
    // console.log('LOAD_RESELLER_NAME in load ' + JSON.stringify(reseller));
    commit('UPDATE_RESELLER_NAME', { resellerName: reseller.name },
      (err) => {
        console.log(err);
      })
  },
  LOAD_RESELLER_PROFILE: async ({ commit, state }, { reseller }) => {

    let school = await fetchReseller(reseller.name)
    commit('UPDATE_RESELLER_PROFILE', { reseller: school })

  },
  LOAD_RESELLER_LIST: ({ commit, state }) => {
    let resellerList = fetchResellerList
    commit('UPDATE_RESELLER_LIST', { resellerList: resellerList })
  },

  LOAD_RESELLER_STATE_LIST: async ({ commit, state, dispatch }, { usState }) => {

    let resellerStateList = await fetchResellerStateList(usState)
    commit('UPDATE_RESELLER_LIST', { resellerList: resellerStateList });
    commit('UPDATE_SELECTED_STATE', { selectedState: usState});
    dispatch('UNSET_LOADING_STATUS')
  },

  SET_SELECTED_STATE: ({ commit }, { selectedState }) => {
    commit('UPDATE_SELECTED_STATE', { selectedState: selectedState } );
  },

  RESET_SELECTED_STATE: ({ commit }) => {
    let selectedState = '';
    commit('UPDATE_SELECTED_STATE', { selectedState: selectedState } );
  },

};

const getters = {
  resellerName(state) {
    return state.resellerName;
  },
  resellerProfile(state) {
    return state.resellerProfile;
  },
  school(state) {
    return state.resellerProfile;
  },
  resellerList(state) {
    return state.resellerList;
  },
  selectedState: (state) => {
    return state.selectedState;
  },
  studentList(state) {
    return state.studentList;
  },
  // getField,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
