<template>
  <div class="state-reseller">
    <div class="block-page-title">
        <h1>Register</h1>
    </div>
    <div class="cur-student block-page-title">
      <h3>Already a student? <a href="https://prou-classroom.tfpkiii.com"> Login for faster registration.</a></h3>
    </div>
    <div class="block-page-content">
        <article>
            <div class="layout-content">
            </div>
        </article>
    </div>
    <form class="form-group s-state">
      <div class="form-group v-state">
        <v-app id="state-reseller">
          <v-container fluid>
            <v-row align="center">
              <v-col cols="12">
                <v-select
                  :items="states"
                  placeholder="Select your state"
                  item-text="name"
                  item-value="abbreviation"
                  v-model="stateSelect"
                  return-object
                  @change="changedState(stateSelect)"
                  outlined
                  flat
                  background-color="white"
                  height="20px"
                  append-icon="mdi-arrow-down"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <div v-if="loadingStatus === 'state-reseller'" class="loading-status">
                <v-row justify="center" align="center">
                  <v-progress-circular
                    indeterminate
                    color="#CB4727"
                    size="100"
                    :width="8"
                    >
                    Loading
                  </v-progress-circular>
                </v-row>
              </div>
              <div v-if="loadingStatus ==='closed' && resellerList.length > 0">
                <reseller-list></reseller-list>
              </div>
              <div v-else>
                <div v-if="stateAbr && loadingStatus !== 'state-reseller'" class="no-reseller">
                  <div class="u_name">
                    <p>No schools found.</p>
                  </div>
                </div>
                <div v-else>
                  <p>&nbsp;</p>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-app>
      </div>
    </form>

  </div>
</template>

<script>
import states from '../data/states_titlecase.json';
import ProductList from './ProductList.vue';
import ResellerList from './ResellerList';
import LoadingInfo from './LoadingInfo';
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      states,
      items: states,
      // state: [],
      stateName: '',
      stateAbr: '',
      stateSelect: [],
      reseller: [],
      showProducts: false,
    }
  },
  computed: {
    ...mapGetters({
      stateList: 'stateList',
      state: 'selectedState',
      basic: 'basic',
      resellerList: 'resellerList',
      loadingStatus: 'loadingStatus',
    }),

  },
  components: {
    appProductList: ProductList,
    loadingInfo: LoadingInfo,
    resellerList: ResellerList,
  },
  methods: {
    changedState(state) {
      let loadingStatus = 'state-reseller';
      this.$store.dispatch('SET_LOADING_STATUS', { loadingStatus });
      console.log(`StateReseller - changedState state is ${JSON.stringify(state)} and loadingStatus is ${this.loadingStatus}`);

      this.stateName = state.name;
      this.stateAbr = state.abbreviation;
      this.showProducts = false;
      // this.$store.dispatch('LOAD_STATE_LIST', { stateAbr: this.stateAbr });
      this.$store.dispatch('LOAD_RESELLER_STATE_LIST', {usState: this.stateAbr});
      this.$store.dispatch('SET_SELECTED_STATE', { selectedState: this.state });
      // this.$store.dispatch('UNSET_LOADING_STATUS');
    },

    activeState(state) {
      if (!state) {
      }
    },
    setActiveReseller(reseller) {
      this.showProducts = true;
      this.$store.dispatch('SET_LOADING_STATUS', "productListType");
      console.log('Pre loading reseller ', JSON.stringify(reseller));
      this.$store.dispatch('LOAD_RESELLER_NAME', { reseller: reseller });
      this.$store.dispatch('LOAD_RESELLER_PROFILE', { reseller: reseller });
      this.$store.dispatch('LOAD_RESELLER_PRODUCTS', { resellerName: reseller.name });
      let orderStep = 1;
      this.$store.dispatch('LOAD_ORDER_STEP', { orderStep });
      this.$router.push({ path: '/reseller' });
    },
    stateLink(reseller) {
      var resellerLine = reseller.schoolName;
      if (reseller.schoolCity) {
        resellerLine = resellerLine + ', ' + reseller.schoolCity;
      }
      return resellerLine;
    }
  }
}

</script>

<style lang="scss">

  @import "../assets/styles/components/statereseller.scss";

  .block-page-title {
    h3 {
    font-weight: 400;
    a {
        
        text-decoration: none;
        color: #af3a23;
      }
    }
  }
  v-input__icon--append {
    v-icon {
      font-size: 14px;
    }
  }
</style>
