import moment from 'moment';

export const cal_mo = [
  { value: '01', label: 'Jan (01)' },
  { value: '02', label: 'Feb (02)' },
  { value: '03', label: 'Mar (03)' },
  { value: '04', label: 'Apr (04)' },
  { value: '05', label: 'May (05)' },
  { value: '06', label: 'Jun (06)' },
  { value: '07', label: 'Jul (07)' },
  { value: '08', label: 'Aug (08)' },
  { value: '09', label: 'Sep (09)' },
  { value: '10', label: 'Oct (10)' },
  { value: '11', label: 'Nov (11)' },
  { value: '12', label: 'Dec (12)' }
]

export const cal_da = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09',
  '11', '12', '13', '14', '15', '16', '17', '18', '19',
  '21', '22', '23', '24', '25', '26', '27', '28', '29',
  '30', '31'
]

const cal_yr = () => {
  const years = []
  const dateStart = moment().subtract(5, 'years')
  const dateEnd = moment().add(5, 'years')

  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push(dateStart.format('YYYY'))
    dateStart.add(1, 'year')
  }
  return years
}

export {
  cal_yr
}
