<template>
  <div class="shipping-options">
      <h4>Shipping Options</h4>
        <v-container class="panel-body ship-body">
        <!-- <v-radio-group v-model="shippingMode">
          <div v-for="so in shippingOptions" :key="so.value">
            <v-radio :label="so.label" :value="so.value"></v-radio>
          </div>
        </v-radio-group> -->
      <v-select
        :items="shippingOptions"
        item-text="label"
        item-value="value"
        v-model="shippingMode"
        background-color="transparent"
        :class="{ 'is-invalid': submitted &&  v.shippingMode.$invalid == true  }"      
        class="s-state"
      ></v-select>
      </v-container>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import shippingOptions from "../data/shippingOptions.js";

export default {
  name: 'ShippingOptions',
  data() {
    return {
      shippingOptions,
      submitted: true, 
    }
  },
  computed: {
    ...mapFields({
        shippingMode: "orders.orderStatus.shipping_mode",
    }),
  },
}
</script>

<style>
.v-select.v-text-field input {
  background-color: transparent;
}

.v-text-field {
  padding-top: 0px;
}

.v-input__slot {
  padding: 0px;
}

.v-input__control > .v-input__slot::before,
.theme--light.v-text-field > .v-input__control > .v-input__slot::before,
.v-text-field > .v-input__control > .v-input__slot::before {
  border-style: none;
  border-color: transparent;
}

</style>