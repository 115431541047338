<template>
  <div class="order-start-instr">
      <!-- <div class="login-inst" v-html="school.schoolLoginInst"></div> -->
      <div class="s-pword">
        <p>To begin, go to the {{ school.schoolName }} <a href="http://prou-classroom.tfpkiii.com" target="_blank">Student Classroom webpage</a> and login using:</p>
          <ul>
            <li>Student PIN: {{ studentProfile.username }} </li>
            <li>Login Password: {{ studentPword }} </li>
          </ul>
        <p>It is recommended for security purposes that you update your password after your first login.</p>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'OrderStartInst',
  computed: {
    ...mapGetters({
      studentPword: "studentPword",
      studentProfile: "studentProfile",
      school: "school",
    })
  },


}
</script>

<style>

</style>