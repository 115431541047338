import Vue from 'vue';
import Vuex from 'vuex';
import axios from '../utils/vue-axios';
import { getField, updateField } from 'vuex-map-fields';

import courses from './modules/courses';
import reseller from './modules/reseller';
import orders from './modules/orders';
import student from './modules/student'

Vue.use(Vuex);

strict: true;

const basicKey = process.env.D7_BASIC_ACCT;

export default new Vuex.Store({
  state: {
    basic: 'Basic ' + btoa(basicKey),
    status: '',
    orderStep: null,
    loadingStatus: 'closed',
    modalStatus: 'closed',
    submitted: false,
    isValid: true,
  },
  mutations: {
    updateField,
    UPDATE_STATUS: (state, { status }) => {
      state.status = status;
    },
    UPDATE_ORDER_STEP: (state, { orderStep }) => {
      console.log(`UPDATE_ORDER_STEP to ${orderStep}`)
      state.orderStep = orderStep
    },
    UPDATE_LOADING_STATUS: (state, { loadingStatus }) => {
      state.loadingStatus = loadingStatus;
    },
    UPDATE_MODAL_STATUS: (state, { modalStatus }) => {
      state.modalStatus = modalStatus;
    },
    UPDATE_SUBMITTED_STATUS: (state, { submitStatus }) => {
      state.submitted = submitStatus;
    },
    UPDATE_ISVALID_STATUS: (state, { valStatus }) => {
      state.isValid = valStatus;
    }
  },
  actions: {
    SET_LOADING_STATUS: ({ commit }, { loadingStatus }) => {
      // commit('UPDATE_STATUS', { status: 'loading' });
      console.log(`Setting loadingStatus to ${loadingStatus}`);
      if (loadingStatus) {
        commit("UPDATE_LOADING_STATUS", { loadingStatus });
      }
    },
    UNSET_LOADING_STATUS: ({ commit }) => {
      let loadingStatus = 'closed';
      commit("UPDATE_LOADING_STATUS", { loadingStatus });
    },

    SET_MODAL_STATUS: ({ commit }, { modalStatus }) => {
      // Set the modal status to the form opened
      commit("UPDATE_MODAL_STATUS", { modalStatus });
    },
    resetModal: ({ commit }) => {
      let modalStatus = 'closed'
      commit("UPDATE_MODAL_STATUS", { modalStatus });
    },
    LOAD_STATUS_COMPLETE: ({ commit, state }) => {
      commit('UPDATE_STATUS', { status: 'complete' });
    },
    LOAD_ORDER_STEP: ({ commit}, { orderStep }) => {
      console.log(`Setting orderStep to ${orderStep}`)
      commit('UPDATE_ORDER_STEP', { orderStep });
    },
    SET_SUBMITTED_STATUS: ({ commit }, { submitStatus }) => {
      commit('UPDATE_SUBMITTED_STATUS', { submitStatus });
    },
    SET_ISVALID_STATUS: ({ commit }, { valStatus }) => {
      commit('UPDATE_ISVALID_STATUS', { valStatus });
    }
  },
  getters: {
    basic(state) {
      return state.basic;
    },
    loadingStatus(state) {
      return state.loadingStatus;
    },
    orderStep(state) {
      return state.orderStep;
    },
    submitted(state) {
      return state.submitted;
    },
    isValid(state) {
      return state.isValid;
    },
    getField,
  },
  modules: {
    courses,
    orders,
    reseller,
    student,
  },
});
