<template>
  <div class="order-complete">
    <div class="row message-top">
      <h1 class="main_headling">Congratulations On Your Enrollment </h1>
      <p>Thank you for enrolling. Your purchase from {{ school.schoolName }} has been successfully processed. Please save this email for your records. </p>
      <div class="message-yellow">
          Your Personal Identification Number (PIN) is: <strong>{{ studentProfile.username }}</strong>
      </div>
      <p class="message-smaller">
        Please note: You must use the entire PIN (letters & numbers, no spaces) when accessing courses.
      </p>
      <hr class="fat-line" />
    </div>
    <div class="row receipt-body">
      <div class="row receipt-top">
        <div class="order-headline">Order Number: <strong>{{ orderNum }}</strong></div>
        <div class="order-date">Date: <strong>{{ ordDate }}</strong>  Time: <strong>{{ ordTime }}</strong></div>
      </div>
      <order-summary class="row"></order-summary>
      <student-summary class="row"></student-summary>
    </div>
    <order-start-inst class="row"></order-start-inst>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import OrderSummary from './OrderSummary';
import StudentSummary from './StudentSummary';
import OrderStartInst from './OrderStartInst'

export default {

  computed: {
    ...mapGetters({
      school: "school",
      orderDetail: "orderDetail",
      courseSelection: "courseSelection",
      orderReturn: "orderReturn",
      studentProfile: "studentProfile",
      studentPword: "studentPwordTemp",
      // school: "school",
    }),
    orderDetails() {
      return this.orderReturn.orderDetails;
    },
    orderNum() {
      return this.orderDetails.orderInfo.orderSummary.orderNumber;
    },
    ordDate() {
      console.log(`ordDate calc with ${this.orderDetails.orderInfo.orderSummary.order_timestamp}`)
      let dateCreate = new Date(this.orderDetails.orderInfo.orderSummary.order_timestamp);
      // let dateCreate = new Date(parseInt(this.orderDetails.orderInfo.orderSummary.order_timestamp)  * 1000);
      return (dateCreate.getMonth() + 1) + '/' + dateCreate.getDate() + '/' + dateCreate.getFullYear();

    },
    ordTime() {
      let timeCreate = new Date(this.orderDetails.orderInfo.orderSummary.order_timestamp);
      let ampm = 'AM';
      let timeHour = timeCreate.getHours() + 1;
      if (timeHour > 12) {
        ampm = 'PM';
        timeHour = timeHour - 12;
      }
      let oTime = `${timeHour}:${timeCreate.getMinutes() +1}:${timeCreate.getSeconds() + 1} ${ampm}`;
      return oTime;
    }
  },
  components: {
    OrderSummary,
    StudentSummary,
    OrderStartInst,
  },
}
</script>
<style lang="scss">

@import "../assets/styles/components/ordercomplete.scss";

</style>
