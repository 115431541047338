let studentStructure = {};

export default studentStructure = {
  schoolname: null,
  student_pid: null,
  username: null,
  password: null,
  pw_confirm: null,
  name_first: null,
  name_middle: null,
  name_last: null,
  fullname: null,
  email: null,
  email_pref: 1,
  phone_raw: null,
  phone: null,
  address: null,
  address2: null,
  city: null,
  state: null,
  postal_code: null,
  country: 'US',
  lic_no: null,
  type_tid: '106',
  lic_type: '',
  lic_type_name: null,
  lic_exp_raw: null,
  lic_exp: null,
};

