<template>
  <div class="order-view">
    <reseller-header></reseller-header>
    <header style="background: transparent;">
<div class="topinfo">
      <h1>Your Information</h1>
            <p class="order-text">Please complete the following form with your student information.</p>
</div>
    </header>
    <div class="order-info">
<div class="order-summary-holder">
        <div class="order-summary-title">
        <h2 class="panel-title left-title">Order Summary</h2>
        </div>
      <order-summary></order-summary>
</div>
    </div>
    <div v-if="studentMessage && studentMessage.msg === 'Err'" class="err-msg">
      <h4 class="err-title">Sorry, we are unable to register you with the information provided</h4>
      <ul v-for="line in valMessage.valList" :key="line.msg" class="err-msg-list">
        <li>{{ line.msg }} - {{ line.detail }}</li>
      </ul>
<!--      <div v-if="studentMessage.length > 1">
        <ul v-for="line in studentMessage" :key="line.msg">
          <li>{{ line.msg }} - {{ line.detail }}</li>
        </ul>
      </div>
      <div v-else>
        {{ line.msg }} = {{ line.detail }}
      </div>-->
    </div>
    <div v-if="submitted == true && isValid == false" class="err-msg">
      <h4 class="err-title">{{ valMessage.valTitle }}</h4>
    </div>
    <div v-if="loadingStatus === 'orderProcess'" class="loading-status">
      <loading-info></loading-info>
    </div>
    <div v-if="loadingStatus === 'closed'" class="order-process">
      <div v-if="orderStep == 6" class="order-reg">
        <div v-if="loadingStatus === 'orderBill'" class="loading-status">
          <loading-info></loading-info>
        </div>
         <div v-if="loadingStatus ==='closed'">
          <div class="container">
<!--
            <p class="order-text">Please complete the following form with your student information.</p>
-->         <div class="action-row">
              <v-checkbox v-model="studentSame" @click="sameBillAddress()">
              </v-checkbox><p class="order-text">Billing info is same as Student Student info</p>
            </div>
            <div class="row">
              <div class="col">
                <student-profile :v="$v"></student-profile>
              </div>
              <div class="col">
                <order-billing :v="$v"></order-billing>
              </div>
            </div>
          </div>
         </div>
<!--        <div class="accept-terms">
          <v-checkbox v-model="termsAgree"> </v-checkbox><general-info-popup />
        </div>-->
        <div class="accept-buttons">
          <a class="accept-button" @click="orderPrelim()" href="#">Continue</a>
        </div>
      </div>
      <div v-if="orderStep == 7" class="order-billing">
        <div v-if="loadingStatus === 'orderBill'" class="loading-status">
          <loading-info></loading-info>
        </div>
        <div v-if="loadingStatus ==='closed'">
          <p class="order-text">This order is not complete until CheckOut is completed.</p>
          <order-checkout></order-checkout>
        </div>
      </div>
      <div v-if="orderStep == 8" class="order-complete">
        <order-complete></order-complete>
      </div>
    </div>

<!--    <div class="active-modal">
      <modal-frame v-if="dialog === true" @close="dialog = false" scrollable="false">
        <div slot="main">
           <loading-info></loading-info>
          <v-btn
            color="orange darket-1"
            text
            outlined
            @click="dialog = false"
          >
            Close
          </v-btn>
        </div>
      </modal-frame>
    </div>-->

    <reseller-footer></reseller-footer>
  </div>
</template>

<script>
import ResellerHeader from '../components/ResellerHeader';
import ResellerFooter from '../components/ResellerFooter';
import StudentProfile from '../components/StudentProfile.vue';
import OrderBilling from '../components/OrderBilling';
import OrderSummary from '../components/OrderSummary.vue';
import OrderCheckout from '../components/OrderCheckout';
import OrderComplete from '../components/OrderComplete';
import LoadingInfo from '../components/LoadingInfo';
import ModalFrame from '../components/ModalFrame';
import GeneralInfoPopup from "@/components/Order/GeneralInfoPopup.vue";
import { mapFields } from 'vuex-map-fields';
import { required, email, helpers, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { map } from "bluebird";

export default {
  name: "Order",
  data() {
    return {
      dialog: false,
      // ordStatus: '',
      // isValid: true,
      // submitted: false,
      valMessage: {
        valTitle: '',
        valSummary: '',
        valList: []
      },
      billCheck: false,
      // orderId: (this.orderReturn ? this.orderReturn.order_id : '')
    };
  },
  mounted() {
    this.$store.dispatch('UNSET_LOADING_STATUS');
    if (this.orderStep == 0 || this.orderStep == "") {
      this.$router.push("/");
    }
  },
  components: {
    StudentProfile,
    OrderBilling,
    OrderSummary,
    OrderCheckout,
    ResellerHeader,
    ResellerFooter,
    OrderComplete,
    LoadingInfo,
    ModalFrame,
    GeneralInfoPopup,
  },
  computed: {
    ...mapGetters({
      orderStep: 'orderStep',
      orderDetail: 'orderDetail',
      studentProfile: 'studentProfile',
      courseSelection: 'courseSelection',
      orderReturn: 'orderReturn',
      resellerName: 'resellerName',
      studentMessage: 'studentMessage',
      loadingStatus: 'loadingStatus',
      // basic: 'basic',
      submitted: 'submitted',
      isValid: 'isValid'
    }),
    ...mapFields({
      studentFirstName: "student.studentProfile.name_first",
      studentMiddleName: "student.studentProfile.name_middle",
      studentLastName: "student.studentProfile.name_last",
      studentAddress: "student.studentProfile.address",
      studentAddress2: "student.studentProfile.address2",
      studentCity: "student.studentProfile.city",
      studentState: "student.studentProfile.state",
      studentPostalCode: "student.studentProfile.postal_code",
      studentTel: "student.studentProfile.phone",
      studentEmail: "student.studentProfile.email",
      studentLicType: "student.studentProfile.lic_type",
      studentLicNo: "student.studentProfile.lic_no",
      studentLicExp: "student.studentProfile.lic_exp",
      studentPword: "student.studentProfile.password",
      passwdConfirm: "student.studentProfile.pw_confirm",
      studentSame: "orders.orderStatus.billing_info.student_same",
      billingFullname: "orders.orderStatus.billing_info.billing_fullname",
      billingAddress: "orders.orderStatus.billing_info.billing_address",
      billingAddress2: "orders.orderStatus.billing_info.billing_address2",
      billingCity: "orders.orderStatus.billing_info.billing_city",
      billingState: "orders.orderStatus.billing_info.billing_state",
      billingPostalCode: "orders.orderStatus.billing_info.billing_postal_code",
      billingCountry: "orders.orderStatus.billing_info.billing_country",
      termsAgree: "orders.orderStatus.terms_agree",
    }),
/*    billCheck() {
      return (this.orderDetail.billing_info.student_same ? this.orderDetail.billing_info.student_same : false);
    },*/
    errMessageDetail: () => {
      let detail
      if (this.studentMessage.msg === 'Err') {
        detail = this.studentMessage.detail;
      } else {
        detail = '';
      }
      return detail;
    },
    orderId: () => {
      let ordId = ''
      if (this.orderDetail.order_id) {
        ordId = this.orderDetail.order_id;
      }
      return ordId;
    },
    /*orderStatus: async () => {
      return await this.studentMessage.msg;
    },
    messageDetail: () => {
      let detail
      if (this.orderStatus === 'Err') {
         detail = JSON.parse(this.studentMessage.detail);
      } else {
        detail = '';
      }
      return detail;
    }*/
  },
  validations() {
    // Licensing info - if type is selected and != 106 (none) then these are required
/*     if (this.studentLicType && this.studentLicType != '106') {
      return {
        expMo: {
          required
        },
        expDay: {
          required
        },
        expYr: {
          required
        }
      }
    } */
    return {
      studentFirstName: { required },
      studentLastName: { required },
      studentAddress: { required },
      studentCity: { required },
      studentState: { required },
      studentPostalCode: { required },
      studentEmail: { required, email },
      studentPword: { required },
      passwdConfirm: {
        sameAsPword: sameAs('studentPword'),
      },
      billingFullname: { required },
      billingAddress: { required },
      billingCity: { required },
      billingState: { required },
      billingPostalCode: { required },
      billingCountry: { required },

      billingGroup: [
        'billingFullname',
        'billingAddress',
        'billingCity',
        'billingState',
        'billingPostalCode',
      ],
      studentGroup: [
        'studentFirstName',
        'studentLastName',
        'studentAddress',
        'studentCity',
        'studentState',
        'studentPostalCode',
        'studentEmail',
        'studentPword'
      ]
    }
  },
  updated() {
    // Make sure loading status is closed
    this.$store.dispatch("UNSET_LOADING_STATUS");
    if (this.orderStep === 0 || this.orderStep == '') {
      this.$route.push('/');
    }
  },
  methods: {
    studentStep(orderStep) {
      // let orderStep = 7;
      this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
    },
    // Does general validation before passing off to save order stuff
    // eslint-disable-next-line consistent-return
    orderPrelim() {
      this.dialog = true;
      const loadingStatus = 'orderBill';
      if (this.billCheck) {
        this.billingFullname = this.studentProfile.name_first + ' ' + this.studentProfile.name_last;
        this.billingAddress = this.studentProfile.address;
        this.billingAddress2 = this.studentProfile.address2;
        this.billingCity = this.studentProfile.city;
        this.billingState = this.studentProfile.state;
        this.billingPostalCode = this.studentProfile.postal_code;
        this.billingCountry = this.studentProfile.country;
      }
      this.$store.dispatch('SET_LOADING_STATUS', { loadingStatus });
      this.$store.dispatch('SET_ISVALID_STATUS', { valStatus: true });
      this.$store.dispatch('SET_SUBMITTED_STATUS', { submitStatus: true });
      // Here we test the validations:
      if (this.$v.billingGroup.$invalid === true || this.$v.studentGroup.$invalid === true) {
        // let valStatus = false;
        this.$store.dispatch('SET_ISVALID_STATUS', { valStatus: false });
        this.valMessage.valTitle = 'Not all required fields are valid/completed';
      }
      if (this.isValid === true) {

        // this.$store.dispatch('SET_LOADING_STATUS', { loadingStatus });
        this.studentProfile.schoolname = this.resellerName;
        const studentProfile = this.studentProfile;

        return this.$store.dispatch('ADD_NEW_STUDENT', { studentProfile })
          .then(() => {
            // this.studentMessage = result;
            console.log(`StudentMessage returned was ${JSON.stringify(this.studentMessage)}`);
            if (this.studentMessage.msg === 'Success') {
              this.orderSecond();
            }
            else {
              this.valMessage.valList = this.studentMessage.detail.errMesg;
              this.$store.dispatch('UNSET_LOADING_STATUS');
              this.dialog = false;
            }
          })
          .catch((err) => {
            console.log(err);
          })
      } else {
        this.dialog = false;
      }
    },
    // Initial save order stuff
    async orderSecond() {
      let orderDetail = this.orderDetail;
      orderDetail.student_id = this.studentProfile.username;
      orderDetail.order_status = 'Pending';
      orderDetail.billing_info.billing_email = this.studentProfile.email;
      // let authBasic = this.basic;
      await this.$store.dispatch('submitOrder', { orderDetail })
      console.log(`OrderReturn item in orderSecond = ${JSON.stringify(orderDetail)}`)
      const orderId = orderDetail.order_id;
      if (orderId) {
        console.log(`Check orderSecond orderReturn = ${JSON.stringify(this.orderResponse)}`)
        this.$store.dispatch('SET_SUBMITTED_STATUS', false );
        this.$store.dispatch('SET_ISVALID_STATUS', true );
        // this.dialog = false;
        let orderStep = 7;
        this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
      }
    },
    // Billing Same
    sameBillAddress() {
      // console.log('BillCheck before = ', this.billCheck);
      this.billCheck = !this.billCheck;
      // console.log('BillCheck = ', this.billCheck);
      if (this.billCheck === true) {
        //var studentBilling = this.studentProfile;
        // console.log(`Bill Check true is ${JSON.stringify(this.studentProfile)}`);
        this.studentSame = true;
        let bMidName = (this.studentProfile.name_middle ? this.studentProfile.name_middle : "");
        if (this.studentProfile.name_first) {
          let bFullname =  `${this.studentProfile.name_first} ${bMidName} ${this.studentProfile.name_last}`;
        } else {
          let bFullname = "Same as Student";
        }
        // let bFullname =  `${this.studentProfile.name_first} ${bMidName} ${this.studentProfile.name_last}`;

        this.billingFullname = "Same as Student Info";
        this.billingAddress = " ";
        this.billingAddress2 = " ";
        this.billingCity = " ";
        this.billingState = " ";
        this.billingPostalCode = " ";
        this.billingCountry = " ";
        /* this.billingAddress = this.studentProfile.address;
        this.billingAddress2 = this.studentProfile.address2;
        this.billingCity = this.studentProfile.city;
        this.billingState = this.studentProfile.state;
        this.billingPostalCode = this.studentProfile.postal_code;
        this.billingCountry = this.studentProfile.country; */

      } else {
        this.billingFullname = "";
        this.billingAddress = "";
        this.billingAddress2 = "";
        this.billingCity = "";
        this.billingState = "";
        this.billingPostalCode = "";
        this.billingCountry = "";
      }

    },
  }
};
</script>

<style lang="scss">
  @import "../assets/styles/views/order.scss";
</style>
