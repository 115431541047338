<template>
  <div class="student-container">
    <div v-if="message">{{ message }}</div>
    <div v-else>
      <h2 class="panel-title">Student Information</h2>
    </div>
    <div class="form-wrapper">
      <v-app>
      <form v-on:submit.prevent="submitHandler">
        <div class="row">
          <div class="col-sm-12 col-med-12">
            <div
              v-if="submitted && v.studentFirstName.$invalid == true"
              class="invalid-feedback"
            >First Name is required</div>
            <input
              type="text"
              class="form-control fname"
              placeholder="First Name"
              v-model="studentFirstName"
              :class="{ 'is-invalid': submitted && v.studentFirstName.$invalid == true }"
              id="name_first"
            >

            <input
              type="text"
              class="form-control mname"
              placeholder="Middle Name"
              v-model="studentMiddleName"
              id="name_middle"
            >
            <div
              v-if="submitted &&  v.studentLastName.$invalid == true "
              class="invalid-feedback"
            >Last Name is required.</div>
            <input
              type="text"
              class="form-control lname"
              placeholder="Last Name"
              v-model="studentLastName"
              :class="{ 'is-invalid': submitted &&  v.studentLastName.$invalid == true  }"
              id="name_last"
            >

            <div
              v-if="submitted &&  v.studentAddress.$invalid == true "
              class="invalid-feedback"
            >Streed address is required</div>
            <input
              type="text"
              class="form-control s-add"
              placeholder="Address"
              v-model="studentAddress"
              :class="{ 'is-invalid': submitted &&  v.studentAddress.$invalid == true  }"
              id="address"
            >


            <input
              type="text"
              class="form-control s-add"
              placeholder="Address2 (Optional)"
              v-model="studentAddress2"
              id="address2"
            >

            <div
              v-if="submitted &&  v.studentCity.$invalid == true "
              class="invalid-feedback"
            >City is required</div>
            <input
              type="text"
              class="form-control s-city"
              placeholder="Town/City"
              v-model="studentCity"
              :class="{ 'is-invalid': submitted &&  v.studentCity.$invalid == true  }"
              id="city"
            >

            <div
              v-if="submitted &&  v.studentState.$invalid == true "
              class="invalid-feedback"
            >State is required</div>
            <v-select
              :items="states"
              placeholder="Choose State"
              item-text="name"
              item-value="abbreviation"
              v-model="studentState"
              outlined
              background-color="white"
              class="s-state"
              :class="{ 'is-invalid': submitted &&  v.studentState.$invalid == true }">
            </v-select>
            <div
              v-if="submitted &&  v.studentPostalCode.$invalid == true"
              class="invalid-feedback"
            >Zip Code is required</div>
            <cleave
              type="text"
              class="form-control s-zip"
              placeholder="Zip Code"
              :options="options.zipCode"
              v-model="studentPostalCode"
              :class="{ 'is-invalid': submitted &&  v.studentPostalCode.$invalid == true }"
              id="postal_code"
            ></cleave>

            <div
              v-if="submitted &&  v.studentEmail.$invalid == true "
              class="invalid-feedback"
            >Email Address is required</div>
            <input
              type="text"
              class="form-control s-email"
              placeholder="Email"
              v-model="studentEmail"
              :class="{ 'is-invalid': submitted &&  v.studentEmail.$invalid == true }"
              id="email"
            >

            <cleave
              class="form-control s-tel"
              placeholder="Telephone (Optional)"
              v-model="studentTel"
              name="studentTel"
              id="phone"
              :options="options.custTel">
            </cleave>


          <h4 class="lic-title">Professional License</h4>

            <div class="lic-group">

              <v-select
                  :items="lic_types"
                  placeholder="Select License Type"
                  item-text="label"
                  item-value="value"
                  v-model="studentLicType"
                  outlined
                  background-color="white"
                  class="l-type">
              </v-select>

              <input
                type="text"
                class="form-control"
                placeholder="License ID"
                v-model="studentLicNo"
                id="lic_no"
                :disabled="studentLicType === '106' || studentLicType === ''"
              >

              <div class="lic-exp">
<!--
                <h5>Expires</h5>
-->
                <cleave
                    class="form-control"
                    ref="studentLicExp"
                    placeholder="MM/DD/YYYY"
                    :options="{
                      date: true,
                      delimiter: '/',
                      datePattern: ['m', 'd', 'Y']}"
                    v-model="studentLicExp"
                    id="studentLicExp"
                    :disabled="studentLicType === '106'"
                >
                </cleave>
              </div>

            </div>

            <div class="show-password" v-if="showPasswd">
              <h4 class="pwd-title">Password</h4>
              <p>Used to access your classroom enrollment.</p>

              <div
                v-if="submitted &&  v.studentPword.$invalid == true"
                class="invalid-feedback"
              >Password is required</div>
              <input
                type="password"
                class="form-control pwd"
                v-model="studentPword"
                placeholder="Password"
                id="password"
                :class="{ 'is-invalid': submitted &&  v.studentPword.$invalid == true }"
              >

              <div
                v-if="submitted && v.passwdConfirm.$invalid == true"
                class="invalid-feedback"
              >Password confirmation does not match </div>
              <input
                type="password"
                class="form-control pwd"
                v-model="passwdConfirm"
                placeholder="Confirm Password"
                id="passwd_confirm"
                :class="{ 'is-invalid': submitted &&  v.passwdConfirm.$invalid == true }"
              >
            </div>
          </div>
        </div>
      </form>
      </v-app>
    </div>

  </div>
</template>

<script>
// import HeaderNav from './HeaderNav.vue';
import Datepicker from "vuejs-datepicker";
import states from "../data/states_titlecase.json";
// import vSelect from "vue-select";
import countries from "../data/countries.js";
import { orderStructure } from "../data/orderStructure.js";
import moment from 'moment'
import * as dateitems from '../data/dateitems'
// import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us.js';
import { mapGetters } from "vuex";
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
// import { required, email, helpers } from "vuelidate/lib/validators";

console.log(`DataItems are ${JSON.stringify(dateitems)}`)

export default {
  data() {
    return {
      states: states,
      // countries: countries,
      items: states,
      lic_exp: "",
      expMo: "",
      expDay: "",
      expYr: "",
      // passwdConfirm: "",
      showPasswd: true,
      showVal: false,
      submitAction: "",
      lic_format: "yyyy-MM-dd hh:mm:ss",

      lic_types: [
        { value: "106", label: "Not Licensed" },
        { value: "104", label: "Salesperson" },
        { value: "122", label: "MLO/NMLS"},
        { value: "107", label: "Broker" }
      ],
      sLicExp: '',
      options: {
        licExp : {
          date: true,
          datePattern: ['M', 'D', 'Y']
        },
        custTel: {
          phone: true,
          phoneRegionCode: 'US',
          delimiter: '-',
        },
        zipCode: {
          blocks: [5, 3],
        },
      },
      message: "",
      orderBase: orderStructure.orderStructure,
      line_item: [
        {
          item_no: "",
          sku: "",
          item_type: "",
          quantity: "",
          item_price: "",
          item_discount: ""
        }
      ],
      ca_mo: dateitems.cal_mo,
      ca_da: dateitems.cal_da,
      menu: 'Select Date',
      // ca_yr: dateitems.cal_yr,
    }
  },
  props: {
    v: Object,
  },
  components: {
    // Cleave,
    Datepicker,
  },
  methods: {
    changeView() {
      return (this.showVal = !this.showVal);
    },
    submitHandler(e) {
      // this.submitted = true;
      var Action = this.submitAction;
      console.log("Handler action ", Action);
      if (this.submitAction === "delete") {
        alert("This would remove the student from the database");
      } else {
        this.$validator.validate().then(valid => {
          if (valid) {
            alert(
              "This will update the student record\n\n" +
                JSON.stringify(this.user)
            );
          }
        });
      }
    },

    goEnrollStudent() {
      // Clear any risidual order
      if (!this.orderDetail) {
        this.orderDetail = this.orderBase;
      }
      // Create a new order

      var orderId =
        this.resellerName +
        "_" +
        this.studentProfile.username +
        "_" +
        Date.now();
      this.orderDetail.order_id = orderId;
      var order = this.orderDetail;
      console.log("Setting Enroll ORder " + JSON.stringify(order));
      this.$store.dispatch("LOAD_STUDENT_ORDER", { order: order });
      this.$router.push({ name: "enroll" });
    },

    pwordTest() {
      if (this.studentPword != this.passwordConfirm) {
        this.$store.dispatch('SET_ISVALID_STATUS', { valStatus: false });
        return false;
      }
      return true;
    },


  },
  mounted() {
    // console.log(this.$refs.studentLicExp.cleave.getISOFormatDate())
  },
  computed: {
    ...mapGetters({
      resellerName: "resellerName",
      studentProfile: "studentProfile",
      studentCourses: "studenTranscript",
      orderDetail: "orderDetail",
      state: 'selectedState',
      basic: "basic",
      submitted: 'submitted',
      isValid: 'isValid'
    }),
    ...mapFields({
      studentFirstName: "student.studentProfile.name_first",
      studentMiddleName: "student.studentProfile.name_middle",
      studentLastName: "student.studentProfile.name_last",
      studentAddress: "student.studentProfile.address",
      studentAddress2: "student.studentProfile.address2",
      studentCity: "student.studentProfile.city",
      studentState: "student.studentProfile.state",
      studentPostalCode: "student.studentProfile.postal_code",
      studentTel: "student.studentProfile.phone",
      studentEmail: "student.studentProfile.email",
      studentLicType: "student.studentProfile.lic_type",
      studentLicNo: "student.studentProfile.lic_no",
      studentLicExp: "student.studentProfile.lic_exp",
      studentPword: "student.studentProfile.password",
      passwdConfirm: "student.studentProfile.pw_confirm",
    }),
    ca_yr() {
      const years = []
      const dateStart = moment()
          .subtract(5, 'years')
      const dateEnd = moment()
          .add(5, 'years')
      console.log(`DateStart ${dateStart} to DateEnd ${dateEnd}`)

      while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'))
        dateStart.add(1, 'year')
      }
      return years
    },
  }
};
</script>

<style lang="scss">

@import "../assets/styles/components/studentprofile.scss";

  .l-type.v-select {

    .v-select__selection {
      color: #000;
      padding: 10px 20px;
    }
    .v-input__icon {
      padding-right: 10px;
    }
    .v-input__slot{
      border-radius: 10px;

      fieldset, fieldset:hover {
        border-color: transparent;
      }
    }
  }
</style>
