// import axios from '../../utils/vue-axios'
// import transReturn from '../../data/transReturn'
import { orderStructure } from '../../data/orderStructure'
import { createNewOrder, fetchSingleOrder, submitTransaction } from '@/utils/orderServices'
import {fetchPackageCourses} from "@/utils/productsServices";

/*const getInitialState = () => {

  const initialOrderState = orderStructure;

  return {
    orderStatus: initialOrderState,
    packageItems: [],
    courseSelection: [],
    orderResponse: [{ order_id: '' }],
    checkoutTrans: [],
    orderMessage: [],
  };
};*/
const initialOrderState = {
  orderStatus: orderStructure(),
  packageItems: [],
  courseSelection: [],
  orderResponse: [{ order_id: '' }],
  checkoutTrans: [],
  orderMessage: [],
}

const state = () => ({ ...initialOrderState });

const mutations = {
  UPDATE_STUDENT_ORDER: (state, { order }) => {
    console.log('Order in mutation ', JSON.stringify(order));
    state.orderStatus = order;
  },
  UPDATE_PACKAGE_COURSES: (state, { packageItems }) => {
    state.packageItems = packageItems;
  },
  UPDATE_ORDER_SUMMARY: (state, { orderSummary }) => {
    state.orderSummary = orderSummary
  },
  CLEAR_ORDER: (state) => {
    // state.order = getInitialState();
    state.activeProduct = [];
    state.selectedCourses = [];
    state.orderStatus = orderStructure();
  },
  SET_ORDER_MESSAGE: (state, { orderMessage }) => {
    state.orderMessage = orderMessage
  },
  addLineItem(state) {
    state.orderStatus.line_item.push({
      item_no: '',
      sku: '',
      item_title: '',
      item_type: '',
      quantity: '',
      item_price: '',
      item_discount: ''
    });
  },

  UPDATE_COURSE_SELECTION: (state, { courseSelection }) => {
    state.courseSelection = courseSelection;
  },

  UPDATE_ORDER_RESPONSE: (state, { orderResponse }) => {
    state.orderResponse = orderResponse;
  },

  addCourseItem(state) {
    state.courseSelection.push({
      item_no: '',
      line_item_no: '',
      course_id: '',
      course_sku: '',
      course_title: ''
    });
  },

  REMOVE_LINE_ITEM: (state, li) => {
    state.orderStatus.line_item.splice(li, 1);
  },

  RESET_COURSES: (state) => {
    state.packageItems = [];
  },

  REMOVE_COURSE_ITEM: (state, ci) => {
    console.log('Remove Course Item no ', ci);
    state.courseSelection.splice(ci, 1);
  },

  RESET_COURSE_SELECTION: (state) => {
    state.courseSelection = [];
  },

  UPDATE_CHECKOUT: (state, trans) => {
    state.checkoutTrans = trans;
  },
  RESET_CHECKOUT: (state) => {
    state.checkoutTrans = [];
  }
};

const actions = {
  ORDER_RESET({ commit }) {
    console.log('Cancelling order w/orderStructure', JSON.stringify(orderStructure()));
    commit('RESET_COURSES')
    commit('RESET_COURSE_SELECTION')
    commit('CLEAR_ORDER')
    commit('RESET_CHECKOUT')
    let orderResponse = [{ order_id: '' }]
    commit('UPDATE_ORDER_RESPONSE', { orderResponse })
  },

  COURSES_CLEAR({ commit }) {
    commit('RESET_COURSES')
    commit('RESET_COURSE_SELECTION')
  },

  CourseSelectionClear({ commit }) {
    commit('RESET_COURSE_SELECTION')
  },

  LOAD_STUDENT_ORDER: ({ commit, state }, { order }) => {
    console.log('Order data comming in ' + JSON.stringify(order));
    commit('UPDATE_STUDENT_ORDER', { order });
  },

  FETCH_STUDENT_ORDER: ({ commit, dispatch }, { orderId, resellerId }) => {
    return new Promise((resolve, reject) => {
      return fetchSingleOrder(orderId, resellerId)
        .then(response => {
          // console.log('order response ', JSON.stringify(response));
          commit('UPDATE_ORDER_SUMMARY', { orderSummary: response.data });
          dispatch('UNSET_LOADING_STATUS');
          resolve(response);
        })
        .catch(err => {
          console.log('Single Order Error', err);
          reject(err);
        })
    })
  },


  LOAD_COURSE_SELECTION: ({ commit, state }, { courseSelection }) => {
    commit('UPDATE_COURSE_SELECTION', { courseSelection });
  },

  LOAD_PACKAGE_COURSES: async ({ commit }, { packageId }) => {
    console.log('Package ID in load ' + packageId)

    const packageItems = await fetchPackageCourses(packageId)

    commit('UPDATE_PACKAGE_COURSES', { packageItems: packageItems })
    // console.log('Package Items in load ' + JSON.stringify(packageItems));

    let groupNo = packageItems.packageContent.length;
    let package_groups = packageItems.packageContent;
    for (let i = 0; i < groupNo - 1; i++) {
      if (package_groups[i].groupSelection === "0") {
        console.log('Package Items in load ' + JSON.stringify(package_groups[i].packageStructure));
        /*let item_content = package_groups[i].item_content
        let courseSelection = item_content*/
        /*let courseSelection = package_groups[i].packageStructure;
        commit('UPDATE_COURSE_SELECTION', { courseSelection })*/
      }
    }
  },

  submitOrder: async ({ commit, state }, { orderDetail }) => {

    orderDetail.course_details = state.courseSelection;
    // console.log('submitOrder authBasic', authBasic)

    let orderMessage = {}
    commit('SET_ORDER_MESSAGE', { orderMessage: orderMessage })

    let order = {};
    order.orderDetail = orderDetail;
    // console.log('submitOrder order', JSON.stringify(order));

    const orderResp = await createNewOrder(order)

      .then((orderResp) => {

        const createOrder = orderResp.resp.data.createOrder
        // console.log(`Testing orderResp status ${JSON.stringify(createOrder)}`)

        // commit('UPDATE_ORDER_RESPONSE', { orderResponse: responce })
        console.log(`Testing orderResp status ${(createOrder.status)}`)
        // console.log(`CreateNewOrder THEN orderResp is ${JSON.stringify(createOrder)}`)
        if (createOrder.status == 200) {
          // const orderContent = responce.createOrder
          // console.log(`After Testing orderResp status ${JSON.stringify(createOrder)}`)
          const orderResponse = {
            order_id: createOrder.order.order_id,
            orderDetails: createOrder.orderSummary,
            lineItems: createOrder.lineItems
          }
          console.log(`Reaching OrderResponse ${JSON.stringify(orderResponse)}`)
          let orderStatus = (state.orderStatus == undefined ? '{msg: "NotFound"}' : state.orderStatus)
          // console.log(`Order before update ${JSON.stringify(state.orderStatus)}`)

          commit('UPDATE_ORDER_RESPONSE', { orderResponse: orderResponse})

          // const orderSummary = orderResponse.orderDetails.orderSummary

          orderStatus.order_id = createOrder.order.order_id
          orderStatus.order_status = createOrder.order.status
          console.log('Order before update ', JSON.stringify(orderStatus))
          commit('UPDATE_STUDENT_ORDER', { order: orderStatus })
        }
/*        else {
          orderMessage = {
            status: orderResp.status,
            errMessage: orderResp
          }
        }*/
      })
      .catch((err) => {
        console.log(`submitOrder Error ${err}`)
        orderMessage = {
          status: 'Server Error',
          errStatus: err.status,
          errMessage: 'System Error, cannot process this order at this time'
        }
        commit('SET_ORDER_MESSAGE', { orderMessage: orderMessage })
      })
  },

  submitCheckout: async ({ commit, state }, { transaction }) => {

    /*let order_id = state.orderResponse.orderDetails.order_id;
    transaction.order.id = order_id;
    let authBasic = state.basic;*/

    // let trans = [];
    console.log('submitOrder transaction ', JSON.stringify(transaction))

    const transFull = await submitTransaction(transaction)
      .then((transFull) => {

        let transReturn = {
          result: transFull.result,
          respMsg: transFull.result_message,
          refId: transFull.trans_id,
          authCode: transFull.auth_code,
          fullReturn: transFull
        }
        commit('UPDATE_CHECKOUT', { trans: transReturn });
      })
      .catch((err) => {
        console.log('submitOrder transaction Error ', err);
        let transReturn = {
          status: 400,
          error: err
        }
      })
  },

  addLineItem: ({ commit }) => {
    commit('addLineItem');
  },
  removeLineItem: ({ commit, dispatch, state }, li) => {
    console.log('State removing ' + JSON.stringify(li));
    if (typeof li === 'object') {
      li = li.li;
    }
    let line_item = state.orderStatus.line_item[li];
    let line_item_no = line_item.item_no;
    // Remove the courses related to this line item
    // let courses = state.courseSelection;
    let array_l = state.courseSelection.length - 1;
    // courses.forEach(function(course) {
    //for (let course of courses) {
    while (array_l >= 0) {
      // line_item = state.courseSelection[array_l].line_item_no;
      console.log(
        'Course in removeLineItem ',
        JSON.stringify(state.courseSelection[array_l])
      );
      if (state.courseSelection[array_l].line_item_no == line_item_no) {
        //   let sku = course.course_sku;
        //
        console.log('ArrayItem to remove w/commit ', array_l);
        commit('REMOVE_COURSE_ITEM', array_l);
        //   dispatch('removeCourseItem', { sku });
      }
      array_l--;
    }

    console.log('Remove item full ', JSON.stringify(line_item));
    // commit('REMOVE_LINE_ITEM', li);
    if (state.orderStatus.line_item.length > 1) {
      commit('REMOVE_LINE_ITEM', li);
    } else {
      commit('REMOVE_LINE_ITEM', li);
      commit('addLineItem');
    }
  },

  removeLineSku: ({ dispatch, state }, sku) => {
    console.log('Removing LineSku ', sku.sku);
    let line_items = state.orderStatus.line_item;
    for (let line_item of line_items) {
      if (line_item['sku'] == sku.sku) {
        let li = line_items.indexOf(line_item);
        console.log('LineSku li ', JSON.stringify(li));
        dispatch('removeLineItem', { li });
      }
    }
  },

  removeCourseItem: ({ commit, state }, sku) => {
    let remove_sku = sku.sku;
    let courses = state.courseSelection;
    if (courses.length > 1) {
      for (let course of courses) {
        if (course['course_sku'] == remove_sku) {
          let ci = courses.indexOf(course);
          commit('REMOVE_COURSE_ITEM', ci);
          break;
        }
      }
    } else {
      commit('REMOVE_COURSE_ITEM', 0);
      commit('addCourseItem');
    }
  },

  ProdReset: ({ commit, dispatch }) => {
    let line_items = state.orderStatus.line_item;
    console.log('ProdReset Action', JSON.stringify(line_items));
    for (let line_item of line_items) {
      let li = line_items.indexOf(line_item);
      console.log('LineSku li ', JSON.stringify(li));
      dispatch('removeLineItem', { li });
    }
  },
}


const getters = {
  orderDetail: (state) => {
    return state.orderStatus;
  },
  packageItems: (state) => {
    return state.packageItems;
  },
  courseDetails: (state) => {
    return state.orderStatus.course_details;
  },
  courseOptionType: (state) => {
    return state.packageItems.option_type;
  },
  courseSelection: (state) => {
    return state.courseSelection;
  },
  orderReturn: (state) => {
    return state.orderResponse;
  },
  checkoutTrans: (state) => {
    return state.checkoutTrans;
  },
  shippingInfo: (state) => {
    return state.orderStructure.shipping_info;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
