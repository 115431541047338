import { basicAxios } from './vue-axios/axios'
import { authToken } from "@/utils/commonService.js";
// import axios from './vue-axios'

let studentReturn = [];
const uName = process.env.VUE_APP_D7_USERNAME
const pW = process.env.VUE_APP_D7_PW
// const authBasic = btoa(`${uName}:${pW}`);


const auth_token = authToken()
  /*new Promise((resolve, reject) => {
  basicAxios.post('/prou_api/v1/profiles/user/token')
    .then((response) => {
      resolve(response);
      return response.data.token;
    })
    .catch((err) => {
      console.log(err);
      reject(err);
      return err;
    });
});
*/
// export default {

export function getStudentProfile(student) {
  const reseller = student.reseller
  const studentPid = student.studentPid

  let callUrl = `prou_api/v1/reseller_student/students/${reseller}/${studentPid}`
  console.log(`fetchSProfile with ${JSON.stringify(student)} => ${callUrl}`)
  return new Promise((resolve, reject) => {
    basicAxios.get(callUrl,
      { headers: {
        'Content-Type': 'application/json',
        // 'X-Csrf-token': auth_token
      }
      })
      .then((response) => {
        const studentProfile = response.data;
        resolve(response);
        return studentProfile;
      }, (err) => {
        console.log(err);
        reject(err);
      });
  });
}

export async function addNewStudent(studentProfile) {
    return await basicAxios
      .post(`/prou_api/v1/reseller_student/students/${studentProfile.schoolname}`,
        {
          headers: {
          'Content-Type': 'application/json',
          'X-Csrf-token': auth_token
        },
        data: studentProfile})
      .then((resp) => {
        if (resp.data.status !== '200') {
          // Return to the add student page with copy of the detail
          return studentReturn = { msg: 'Err', profile: resp.data.msg };
          // return studentReturn = { msg: 'Err', resp.data.student };
        } else {
          // Update profile with student array and send to the "student" component
          return studentReturn = { msg: 'Success', profile: resp.data.student };
        }
      })
    .catch((err) => {
      console.log(err);
      return studentReturn = {
        status: 400,
        error: err
      }
    });
}

export function updateStudent(studentProfile) {
  // var auth_token = response.data.token;
  const studentPid = studentProfile.studentPid
  return basicAxios.put(`/prou_api/v1/reseller_student/students/${studentPid}`, { headers: { 'Content-Type': 'application/json', 'X-Csrf-token': auth_token }, data: studentProfile })
    .then((resp) => {
      if (resp.data.msg === 'Error') {
        // Return to the add student page with copy of the detail
        studentReturn = { msg: 'Err', detail: resp.data, profile: studentProfile };
      } else {
        // Update profile with student array and send to the "student" component
        studentReturn = { msg: 'Success', detail: resp.data.msg, profile: resp.data.student };
      }
      return studentReturn
    }, (err) => {
      console.log(err);
    });
}

export const fetchStudentTranscript = async (studentPid) => {
   await basicAxios.get(`prou_api/v1/reseller_student/transcript/${studentPid}`)
    .then((response) => {
      return response.data.transcript;
    }, (err) => {
      console.log(err);
      return `Failed with error ${err}`;
    });
}

export function getStudentClasses(studentPid) {
  let callUrl = `prou_api/v1/reseller_student/transcript_hist/${studentPid}`
  console.log(`Getting transcript with ${uName} / ${pW}`)
  console.log(`Using ${JSON.stringify(studentPid)} to get the Transcripts`)
  return new Promise((resolve, reject) => {
    basicAxios.get(callUrl, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        console.log(`response from getClasses ${JSON.stringify(response)}`);
        resolve(response)
        return response
      }, (err) => {
        console.log(`Error in getClasses ${err}`)
        reject(err)
        return err.response
      })
  })
}
