import axios from '../../utils/vue-axios';
import { fetchCatalogProducts } from '@/utils/catalogServices.js'
import {fetchPackageCourses, fetchProductDetails} from '@/utils/productsServices.js'
import { fetchResellerProdDisplay } from '@/utils/resellerServices.js'

const state = {
  resellerProducts: [],
  productDetail: [],
  packageItems: [],
  productDisplay: [],
  activeType: [],
};

const mutations = {
  UPDATE_RESELLER_PRODUCTS: (state, { resellerProducts }) => {
    state.resellerProducts = resellerProducts;
  },
  UPDATE_PRODUCT_DETAIL: (state, { productDetail }) => {
    state.productDetail = productDetail;
    // Object.keys(productDetail).forEach(function(k){
    //   this.$set(state.productDetail, k, obj[k]);
    // })
  },
  UPDATE_PACKAGE_COURSES: (state, { packageItems }) => {
    state.packageItems = packageItems;
  },
  UPDATE_PRODUCT_DISPLAY: (state, { productDisplay }) => {
    console.log(`Product Display update is ${JSON.stringify(productDisplay)}`)
    state.productDisplay = productDisplay;
  },
  UPDATE_ACTIVE_TYPE: (state, { activeType }) => {
    state.activeType = activeType;
  },
  RESET_PRODUCT_DISPLAY: (state) => {
    state.productDisplay = [];
  },
  RESET_PRODUCT_DETAIL: (state) => {
    state.productDetail = [];
  }
};

const actions = {
  LOAD_RESELLER_PRODUCTS: async function ({ commit, dispatch, state}, { resellerName }) {
    console.log(`ResellerName ${resellerName}`)
    const resellerProducts = await fetchCatalogProducts(resellerName)
    console.log(`resellerPrducts in Cat ${resellerProducts}`)
    commit('UPDATE_RESELLER_PRODUCTS', { resellerProducts: resellerProducts });
    dispatch('UNSET_LOADING_STATUS');
  },
  RESET_PRODUCT_DISPLAY: function ({ commit }) {
    commit('RESET_PRODUCT_DISPLAY');
  },
  LOAD_PRODUCT_DETAIL: async function({ commit, state }, { productDetail }) {
    const prodDetail = await fetchProductDetails(productDetail.sku)
    commit('UPDATE_PRODUCT_DETAIL', { productDetail: prodDetail })
  },
  LOAD_ACTIVE_TYPE: function({commit}, { activeType }) {
    console.log(`Setting activeType to ${JSON.stringify(activeType)}`);
    commit('UPDATE_ACTIVE_TYPE', { activeType: activeType});
  },

  LOAD_PACKAGE_COURSES: async function({commit, state}, { packageId }) {
    const packageItems = await fetchPackageCourses(packageId)
    commit('UPDATE_PACKAGE_COURSES', { packageItems: packageItems })
  },
  LOAD_PRODUCT_DISPLAY: async function ({ commit, dispatch, state }, { productSku, resellerName }) {
    const resellerDisplay = await fetchResellerProdDisplay(resellerName, productSku)
    commit('UPDATE_PRODUCT_DISPLAY', { productDisplay: resellerDisplay })
    dispatch('UNSET_LOADING_STATUS');
  }
};


const getters = {

  resellerProducts: (state) => {
    return state.resellerProducts;
  },
  productDetail: (state) => {
    console.log('productDetail in getters ' + JSON.stringify(state.productDetail));
    return state.productDetail;
  },
  packageCourses: (state) => {
    return state.packageItems;
  },
  productDisplay: (state) => {
    console.log('productDisplay in getters ' + JSON.stringify(state.productDisplay));
    return state.productDisplay;
  },
  productSku: state => state.productDisplay.sku,
  activeType: state => state.activeType,
};

export default {
  state,
  mutations,
  actions,
  getters
};
