import basicAxios from './vue-axios';

// Retreive courses for a package
export const fetchPackageCourses =  async (packageId) => {
  let packageItems = {}
  return await basicAxios
    .get(`prou_api/v1/prou_products/courses_package/${packageId}`)
    .then((response) => {
      return packageItems = response.data;
    })
    .catch((err) => {
      console.log(err);
      return packageItems = {
        status: 400,
        error: err
      }
    });
};


export const fetchProductDetails = async (productSku) =>  {
  let productDetail = {}

  return await basicAxios
    .get(`prou_api/v1/prou_products/product_summary/${productSku}`)
    .then((response) => {
      return productDetail = response.data
    })
    .catch((err) => {
      console.log(err)
      return productDetail = {
        status: 400,
        error: err
      }
    })
}
/*

export default {
  fetchProductDetails,
  fetchPackageCourses
};
*/
