import { basicAxios } from './vue-axios/axios';

export const authToken = async () => {
  let authTok = ''
  return await basicAxios
    .post('/prou_api/v1/profiles/user/token')
    .then((response) => {
      return authTok = response.data.token;
    })
    .catch((err) => {
      console.log(err);
      return authTok = {
        status: 400,
        error: err
      };
    });
}
