<template>
  <div class="reseller-header" v-if="resellerProfile">
    <header>
      <h1 class="logo">
        <div v-if="resellerLogo">
          <img :src="logoUrl" :alt="resellerProfile.schoolName" />
        </div>
        <div v-else>
          {{ resellerProfile.schoolName }}
        </div>
      </h1>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ResellerHeader',
  data () {
    return {
      logoUrl: null,
    }
  },
  computed: {
    ...mapGetters({
      resellerProfile: 'resellerProfile',
    }),
    resellerLogo() {
      
      if (this.resellerProfile.schoolLogo) {
        this.logoUrl = this.resellerProfile.schoolLogo;
      }
      else if (this.resellerProfile.schoolBanner) {
        this.logoUrl = this.resellerProfile.schoolBanner;
      }

      return this.logoUrl;
    }
  },

}
</script>

<style>

</style>
