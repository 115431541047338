<script>
import GeneralInfo from "@/components/GeneralInfo.vue";
import ModalFrame from "@/components/ModalFrame.vue";
import { mapGetters } from "vuex";

export default {
  name: "GeneralInfoPopup",
  data() {
    return {
      dialog: false,
    }
  },
  components: {
    GeneralInfo,
    ModalFrame
  },
  computed: {
    ...mapGetters({
      productDisplay: 'productDisplay',
      school: 'resellerProfile',
    }),
  },
  onMounted() {
    this.dialog = true;
  },
  methods: {
    close() {
      this.dialog = false;
    }
  }
}
</script>

<template>
   <div class="general-info-popup">
     <v-app id="gi-popup">
       <v-row justify="center">
         <v-dialog
             v-model="dialog"
             scrollable
             max-width="80%"
         >
           <template v-slot:activator="{ on, attrs }">
             I have read and agree to this program's
             <a href="#"
                 v-bind="attrs"
                 v-on="on"
             >the terms and conditions
             </a>
           </template>
            <v-card>
              <v-card-title class="gi-card-title">
                <span class="headline">General Information</span>
              </v-card-title>
              <v-card-text>
                <header class="gi-header">
                  <h1>General Information</h1>
                  <h2>{{ school.schoolName }}</h2>
                  <p class="contact">
                    {{ school.schoolAddress.address1 }}
                    <span v-if="school.schoolAddress.address2">
                    {{ school.schoolAddress.address2 }}
                    <br>
                  </span>
                    {{ school.schoolAddress.city }}, {{ school.schoolAddress.state }} {{ school.schoolAddress.postalCode }}
                    <br>
                    Web: {{ school.schoolUrl }}
                    <br>
                    Email: {{ school.schoolEmail }}
                    <br>
                    <span v-if="school.schoolCaBre">CalBRE Sponsor ID: {{ school.schoolCaBre }}</span>
                  </p>
                  <p class="notice">Important Instructions: Please Read Carefully Before You Proceed</p>
                  <p
                      class="alert"
                  >If you understand the information stated herein, please select the "Accept Button" at the bottom of this page to continue your order. Thank you!</p>
                </header>
                <div
                    class="prod-content"
                    v-html="productDisplay.course_type.general_info"
                ></div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="#af3a23"
                    background-color="white"
                    large
                    icon
                    @click="dialog = false"
                >
                  <v-icon>
                    mdi-close-circle-outline
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
         </v-dialog>
       </v-row>
     </v-app>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/styles.css";
.general-info-popup {
  a {
    background-color: transparent;
    padding-left: 4px;
    height: 1.5rem;
  }
  height: 1.5rem;
  .v-card__title {
    background-color: #af3a23;
    .headline {
      color: #fff;
      font-weight: bold;
    }
  }
}
.prod-content {
  background-color: #fff;
  height: 10vh;

  .gi-card-title {
    background-color: #af3a23;
  }

}

.v-card__actions {
   border-top: 1px solid #af3a23;
 }
.v-card__actions > .v-btn.v-btn--icon {
  background-color: transparent;
}

</style>