<template>
  <div class="reseller-footer">
      <div v-if="resellerProfile.schoolFooterTag">
        <div class="footer-tag"><span v-html="resellerProfile.schoolFooterTag"></span></div>
      </div>
      <div v-else>
        <div class="footer-opt">{{ resellerProfile.schoolName }} - {{ resellerProfile.schoolPhone }} - {{ resellerProfile.schoolEmail }} </div>
      </div>
      <div class="footer-lic">
        {{ licenseFooter }}
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
name: 'ResellerFooter',
computed: {
  ...mapGetters({
    resellerProfile: 'resellerProfile',
  }),
  licenseFooter() {
    var lf = '';
    if (this.resellerProfile.schoolCaBre) {
      lf = 'CalBRE Sponsor ID ' + this.resellerProfile.schoolCaBre + ', ';
    }
    if (this.resellerProfile.schoolCE) {
      lf = lf + 'CE#' + this.resellerProfile.schoolCE + ', ';
    }
    if (this.resellerProfile.schoolNmls) {
      lf = lf + 'NMLS#' + this.resellerProfile.schoolNmls;
    }
    return lf;
  },
}

}
</script>

<style>
.reseller-footer {
  padding-bottom: 40px;
}
</style>
