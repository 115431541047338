<template>
  <div class="reseller" v-if="resellerProfile">
    <reseller-header></reseller-header>
    <div class="reseller-content">
      <div v-if="orderStep === 1" class="step1">
        <product-type-list></product-type-list>
      </div>
      <div v-if="orderStep === 2" class="step2">
        <product-list></product-list>
      </div>
      <div class="reseller-bottom">
        <button class="btn btn-warning accept-button startover1" @click="resetSteps">Start Over</button>
      </div>
    </div>
    <reseller-footer></reseller-footer>
  </div>
</template>

<script>
import ResellerHeader from '../components/ResellerHeader';
import ResellerFooter from '../components/ResellerFooter';
import { mapGetters, mapState } from "vuex";
import ProductTypeList from "../components/ProductTypeList.vue";
import ProductList from "../components/ProductList.vue";

export default {
  name: "Reseller",
  data() {
    return {
      logoUrl:
        'http://dev-prou-net.pantheonsite.io/sites/default/files/school/prou_admin/images/'
    };
  },
  components: {
    ProductList,
    ProductTypeList,
    ResellerHeader,
    ResellerFooter,
  },
  mounted() {
    if (
      this.resellerProfile.schoolName === "" &&
      !this.resellerProfile.name
    ) {
      this.resetSteps()
    }
  },
  computed: {
    ...mapGetters({
      resellerProfile: "resellerProfile",
      orderStep: "orderStep",
    }),
    ...mapState({
      orderStep: "orderStep"
    }),
    logoFile() {
      const logoSrc = this.logoUrl + this.resellerProfile.school.schoolLogo;
      return logoSrc;
    },
    licenseFooter() {
      var lf = "";
      if (this.resellerProfile.school.schoolCaBre) {
        lf =
          "CalBRE Sponsor ID " + this.resellerProfile.school.schoolCaBre + ", ";
      }
      if (this.resellerProfile.school.schoolCE) {
        lf = lf + "CE#" + this.resellerProfile.school.schoolCE + ", ";
      }
      if (this.resellerProfile.school.schoolNmls) {
        lf = lf + "NMLS#" + this.resellerProfile.school.schoolNmls;
      }
      return lf;
    }
  },
  methods: {
    resetSteps() {
      let orderStep = 0;
      this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
      this.$store.dispatch("RESELLER_RESET");
      this.$store.dispatch("RESELLER_LIST_RESET");
      this.$store.dispatch("RESET_SELECTED_STATE");
      this.$store.dispatch("ORDER_RESET");
      this.$router.push({ path: "/" });
    }
  }
};
</script>

<style lang='scss'>

@import "../assets/styles/views/reseller.scss";

</style>
