<template>
  <div class="products-list">
    <header>
      <h1>{{ activeType.type_name }}</h1>
      <h2>Step 2</h2>
      <h3>For details or to purchase, select a course</h3>
    </header>
    <div class="prod-list">
      <div class="row prod-list-title">
        <div class="p-title">Course Name</div>
        <div class="p-hours">Hours</div>
        <div class="p-price">Price</div>
      </div>
      <v-radio-group v-model="prod" column>
        <div v-for="product in baseProds" :key="product.sku" class="row p-types">
          <v-radio
            :label="product.title"
            :value="product.sku + ' ' +product.title"
            @change="setActiveProduct(product)">
          </v-radio>
          <div class="p-hours">{{ product.credits }}</div>
          <div class="p-price">{{ product.price / 100 | currency }}</div>
        </div>
      </v-radio-group>
    </div>
    <div class="previous">
      <button class="btn btn-warning accept-button previous1" @click="stepBack()">Previous Page</button>
    </div>
  </div>
</template>

<script>
import PackageItems from "./PackageItems.vue";
import { mapGetters } from "vuex";

export default {
  name: "ProductList",
  data() {
    return {
      activeList: "",
      product_sku: "",
      prod_ship: "",
      showRaw: false,
      activeProduct: "",
      resellerProduct: [],
      prod_type: [],
      prod: [],
    };
  },

  components: {
    appPackageItems: PackageItems
  },

  computed: {
    ...mapGetters({
      resellerProducts: "resellerProducts",
      courseItems: "packageCourses",
      productDetail: "productDetail",
      reseller: "resellerName",
      orderDetail: "orderDetail",
      loadingStatus: "loadingStatus",
      activeType: "activeType",
      basic: "basic",
    }),
    baseProds() {
      const typesProd = this.resellerProducts.products;
      const typeId = this.activeType.type_id;
      console.log("Type ID in baseProds " + typeId);
      let typesProdList = this.resellerProducts.products.filter((product) => {
        console.log("Product in baseProds " + JSON.stringify(product));
        if (typeId == '4' || typeId == '12') {
          return product.course_type_id == typeId || product.course_type_id == '13';
        } else {
          return product.course_type_id == typeId;
        }
        // typesProdList;
      });
      typesProdList.sort((a,b) => {
        return parseInt(a.title) < parseInt(b.title);
      });
      return typesProdList;
    },
    sortProds() {
      const sortProd = this.baseProds
      sortProd.sort((a,b) => {
        return parseInt(a.type_label) > parseInt(b.type_label)
      })

      return sortProd
    }
  },
  methods: {

    productByTypes() {
      console.log("Type in productByTypes " + this.activeType.type_id);
      let type_id = this.activeType.type_id;
      let products = this.resellerProducts.products;

      //console.log('ResellerProducts in productByTypes products ' + JSON.stringify(products));
      return products.filter(a => {
        if (type_id == '4' || type_id == '12'){
          return a.course_type_id === type_id || a.course_type_id  == '13';
        } else {
          return a.course_type_id === type_id;
        }
      });
    },

    setActiveList(type) {
      let loadingStatus = "product-list";
      this.$store.dispatch('SET_LOADING_STATUS', { loadingStatus });
      this.step = 2;
      this.activeType = type.type_label;
      console.log("List type ID is " + type.typeId);
      let orderStep = 2;
      this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
      if (this.activeList === type.typeId) {
        return (this.activeList = "");
      } else {
        return (this.activeList = type.typeId);
      }
      // this.$store.dispatch('UNSET_LOADING_STATUS');
    },

    stepBack() {
      let activeList = "";
      this.$store.dispatch('LOAD_ACTIVE_LIST', { activeList })
      let orderStep = 1;
      this.$store.dispatch('LOAD_ORDER_STEP', { orderStep });
    },


    setActiveProduct(product) {
      var productSku = product.sku;
      var resellerName = this.reseller;
      var loadingStatus = 'productDetail';
      this.$store.dispatch("SET_LOADING_STATUS", { loadingStatus });
      this.$store.dispatch("LOAD_PRODUCT_DISPLAY", { productSku, resellerName });
      let course_count = (this.course_count ? this.course_count : 1);
      this.$router.push({ path: "/product" });
    }
  }
};
</script>
<style lang="scss">

@import "../assets/styles/components/productlist.scss";

</style>
