import { basicAxios } from './vue-axios/axios.js';

const authBasic = `Basic ${btoa(process.env.D7_BASIC_ACCT)}`;

export const fetchCatalogProducts = async (resellerName) => {

  const catUrl = `prou_api/v1/schools/reseller_catalog/${resellerName}`
  console.log(`Getting cat infow with ${catUrl}`)
  return await basicAxios.get(catUrl)
    .then((response) => {
      console.log(`Catalog ${catUrl} response ${JSON.stringify(response)}`)
      return response.data
    }, (err) => {
      console.log(err)
      return err.response
    })
}

/*export default {
  fetchCatalogProducts
}*/
