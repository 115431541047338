<template>
  <div class="order-billing">
    <v-app>
    <div class="panel-heading">
      <h2 class="panel-title">Billing Information</h2>
    </div>
    <div class="panel-body">
<!--      <div class="bill-check">
        <p>
          <input
            id="bill-check"
            type="checkbox"
            v-model="billCheck"
            @change="sameBillAddress()">
            Billing info is same as Student Student info
        </p>
        <div v-if="studentSame">Billing is the same and student info</div>
      </div>-->
      <div
        v-if="submitted &&  v.billingFullname.$invalid == true && !billCheck"
        class="invalid-feedback"
      >Name on the card is required.</div>
      <input
        type="text"
        placeholder="Name on Card"
        class="s-name"
        :disabled="studentSame"
        v-model="billingFullname"
        :class="{ 'is-invalid': submitted &&  v.billingFullname.$invalid == true  }"
        id="fullname">

      <div
        v-if="submitted &&  v.billingAddress.$invalid == true "
        class="invalid-feedback"
      >Billing Street Address is required.</div>
      <input
        type="text"
        placeholder="Billing Address"
        class="s-address"
        :disabled="studentSame"
        v-model="billingAddress"
        :class="{ 'is-invalid': submitted &&  v.billingAddress.$invalid == true  }"
        id="address">


      <input
        type="text"
        placeholder="Billing Address 2 (Optional)"
        class="s-address2"
        :disabled="studentSame"
        v-model="billingAddress2"
        id="address2">

      <div
        v-if="submitted &&  v.billingCity.$invalid == true "
        class="invalid-feedback"
      >Billing Town/City is required.</div>
      <input
        type="text"
        placeholder="Billing Town/City"
        class="s-city"
        v-model="billingCity"
        :disabled="studentSame"
        :class="{ 'is-invalid': submitted &&  v.billingCity.$invalid == true  }"
        id="city">

      <div
        v-if="submitted &&  v.billingState.$invalid == true "
        class="invalid-feedback"
      >Billing State is required.</div>
       <v-select
          :items="states"
          :disabled="studentSame"
          placeholder="Choose State"
          item-text="name"
          item-value="abbreviation"
          v-model="billingState"
          outlined
          background-color="white"
          :class="{ 'is-invalid': submitted &&  v.billingState.$invalid == true  }"
          class="s-state"
        ></v-select>

      <div
        v-if="submitted &&  v.billingPostalCode.$invalid == true "
        class="invalid-feedback"
      >Billing Zip Code is required.</div>
      <cleave
        type="text"
        :disabled="studentSame"
        placeholder="Billing Zip Code"
        :class="{ 'is-invalid': submitted &&  v.billingPostalCode.$invalid == true  }"
        class="s-zip"
        v-model="billingPostalCode"
        :options="options.zipCode"
        id="postal_code">
      </cleave>
    </div>
    </v-app>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component';
import { mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import states from "../data/states_titlecase.json";
import countries from "../data/countries.js";
import { required, email, helpers } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      // billCheck: false,
      states: states,
      items: states,
      state: [],
      countries,
      options: {
        zipCode: {
          blocks: [5, 3],
        },
      },
    }
  },
  props: {
    v: Object,
    // isValid: Boolean,
    // submitted: Boolean,
  },
  computed: {
    ...mapGetters({
      studentProfile: "studentProfile",
      submitted: 'submitted',
      isValid: 'isValid',
      orderState: "orderDetail",
    }),
    ...mapFields({
      billingFullname: "orders.orderStatus.billing_info.billing_fullname",
      billingAddress: "orders.orderStatus.billing_info.billing_address",
      billingAddress2: "orders.orderStatus.billing_info.billing_address2",
      billingCity: "orders.orderStatus.billing_info.billing_city",
      billingState: "orders.orderStatus.billing_info.billing_state",
      billingPostalCode: "orders.orderStatus.billing_info.billing_postal_code",
      billingCountry: "orders.orderStatus.billing_info.billing_country",
      studentSame: "orders.orderStatus.billing_info.student_same",
    }),
    billCheck() {
      return this.studentSame;
    },
  },
  methods: {
    sameBillAddress() {
      // console.log('BillCheck before = ', this.billCheck);
      this.billCheck = !this.billCheck;
      // console.log('BillCheck = ', this.billCheck);
      if (this.billCheck == true) {
        //var studentBilling = this.studentProfile;
        // console.log(`Bill Check true is ${JSON.stringify(this.studentProfile)}`);

        let bMidName = (this.studentProfile.name_middle ? this.studentProfile.name_middle : "");
        let bFullname = null;
        if (this.studentProfile.name.first) {
          let bFullname =  `${this.studentProfile.name_first} ${bMidName} ${this.studentProfile.name_last}`;
        } else {
          let bFullname = "Same as Student";
        }

        this.billingFullname = bFullname;
        this.billingAddress = this.studentProfile.address;
        this.billingAddress2 = this.studentProfile.address2;
        this.billingCity = this.studentProfile.city;
        this.billingState = this.studentProfile.state;
        this.billingPostalCode = this.studentProfile.postal_code;
        this.billingCountry = this.studentProfile.country;

      } else {
        this.billingFullname = "";
        this.billingAddress = "";
        this.billingAddress2 = "";
        this.billingCity = "";
        this.billingState = "";
        this.billingPostalCode = "";
        this.billingCountry = "";
      }

    },
  },

}
</script>

<style lang="scss">

@import '../assets/styles/components/orderbilling.scss';

</style>
