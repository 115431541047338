<template>
  <div class="order-summary">
    <main style="margin:0;">
      <div class="content-wrapper">
        <div class="table-row table-head">
          <div class="course-title">Product Name</div>
          <div class="fee bold">Price</div>
        </div>
        <div class="table-row" v-for="item in orderDetail.line_item" :key="item.item_no">
          <div class="course-title">{{ item.item_title }}</div>
          <div class="fee">${{ item.item_price / 100 }}</div>
          <div v-if="item.textbook_include" class="text-row">
            <div class="text-title">{{ item.textbook_include.title }}</div>
            <div class="text-price">${{ item.textbook_include.price / 100 }}</div>
          </div>
          <div v-if="item.orientation.or_sku" class="or-row">
            <div class="text-title">{{ item.orientation.or_title }}</div>
            <div class="text-price">$ 0</div>
          </div>
        </div>
        <div class="table-row table-footer">
          <div class="course-title">Total</div>
          <div class="fee">${{ orderDetail.order_total / 100 }}</div>
        </div>
      </div>

      <div v-if="orderStep >= 5">
        <div class="course-select" v-if="courseSelection.length > 0">
        <h4>Included Courses</h4>
        <div class="row order-courses" v-for="course in courseSelection" :key="course.course_id">
          <div class="course-sku">{{ course.course_sku }}</div>
          <div v-if="course.course_title" class="course-name">{{ course.course_title }}</div>
          <div v-else class="course-name">{{ course.course_name }}</div>
        </div>
      </div>
        <div v-if="orderDetail.textbook_include">
          <shipping-options></shipping-options>
        </div>
        <div v-if="orderStep === 5">
          <div class="accept-buttons">
            <div class="action-row">
              <button class="accept-button decline" @click="startOver" href="#">Start Over</button>
            </div>
            <div class="action-row">
              <button class="accept-button decline" @click="addLineItem" href="#">Add More</button>
            </div>
            <div class="action-row">
              <button class="accept-button" @click="studentStep" href="#">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import StudentProfile from "../components/StudentProfile.vue";
import ShippingOptions from "../components/ShippingOptions.vue";

export default {
  name: "OrderSummary",

  data() {
    return {
      courseDetail: {
        item_no: "",
        line_item_no: "",
        course_id: "",
        course_sku: "",
        course_title: ""
      }
    };
  },
  components: {
    StudentProfile,
    ShippingOptions
  },
  computed: {
    ...mapGetters({
      school: "school",
      orderDetail: "orderDetail",
      orderStep: "orderStep",
      courseSelection: "courseSelection",
    }),
    ...mapMultiRowFields(["orderDetail.line_item"]),
    lineItem () {
      return this.orderDetail.line_item
    },
    coursesSelected() {
    }
  },
  methods: {
    startOver() {
      let orderStep = 0;
      this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
      this.$store.dispatch("ORDER_RESET");
      this.$store.dispatch("RESET_PRODUCT_DISPLAY");
      this.$router.push({ path: "/" });
    },
    addLineItem() {
      this.$store.dispatch("addLineItem");
      let orderStep = 1;
      this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
      this.$router.push("/reseller");
    },
    studentStep() {
      let orderStep = 6;
      this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
      this.$router.push("/order");
    }
  }
};
</script>

<style lang="scss">

@import "../assets/styles/components/ordersummary.scss";

</style>
