<template>
  <div class="completed-view">
    <reseller-header></reseller-header>
    <order-complete></order-complete>
    <reseller-footer></reseller-footer>
  </div>
</template>

<script>
import ResellerHeader from '../components/ResellerHeader';
import ResellerFooter from '../components/ResellerFooter';
import OrderComplete from '../components/OrderComplete';
import { mapGetters } from "vuex";

export default {
  name: "Completed",
  data() {
    return {

    }
  },
  mounted() {
    this.$store.dispatch('UNSET_LOADING_STATUS');
    if (this.orderStep == 0 || this.orderStep == "") {
      this.$router.push("/");
    }
  },
  components: {
    ResellerHeader,
    OrderComplete,
    ResellerFooter,
  },
  computed: {
    ...mapGetters({
      orderStep: 'orderStep',
    })
  }
}
</script>

<style>

</style>