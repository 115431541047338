// let orderStructure = {};

// export default orderStructure = {
export const orderStructure = () => {
    return {
      order_id: '',
      order_status: '',
      reseller_id: '',
      student_id: '',
      billing_info: {
        student_same: false,
        billing_email: '',
        billing_address: '',
        billing_address2: '',
        billing_city: '',
        billing_state: '',
        billing_postal_code: '',
        billing_country: '',
        billing_fullname: '',
        billing_route: '',
        billing_cc_token: '',
        billing_cc_name: '',
        billing_ccCvv: '',
        billing_ccExpire: '',
      },
      coupon_id: '',
      prod_ship: '',
      line_item: [{
        item_no: '',
        sku: '',
        item_title: '',
        item_type: '',
        quantity: '',
        item_price: '',
        item_discount: '',
      }],
      course_details: [{
        line_item_no: '',
        course_id: '',
        course_sku: '',
        course_title: '',
      }],
      textbook_include: false,
      shipping_mode: '32',
      shipping_info: {
        student_same: false,
        billing_same: false,
        shipping_address: '',
        shipping_address2: '',
        shipping_city: '',
        shipping_state: '',
        shipping_postal_code: '',
        shipping_country: '',
        shipping_fullname: ''
      },
      terms_agree: false,
    }
};
