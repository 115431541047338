import axios from '../../utils/vue-axios';
import studentStructure from '../../data/studentStructure';
import { getStudentProfile, addNewStudent, updateStudent, fetchStudentTranscript } from '@/utils/studentServices.js'
// import { getField, updateField } from 'vuex-map-fields';

const state = {
  studentProfile: studentStructure,
  studentTranscript: [],
  studentMessage: [],
  studentPwordTemp: '',
};

const initialState = studentStructure;

const mutations = {
  UPDATE_STUDENT_PROFILE: (state, { studentProfile }) => {
    console.log('UPDATE_STUDENT_PROFILE ' + JSON.stringify(studentProfile));
    state.studentProfile = studentProfile;
  },
  UPDATE_STUDENT_TRANSCRIPT: (state, { studentTranscript }) => {
    state.studentTranscript = studentTranscript;
  },
  UPDATE_STUDENT_MESSAGE: (state, { studentMessage }) => {
    state.studentMessage = studentMessage;
  },
  UPDATE_TEMP_PW: (state, { studentPword }) => {
    state.studentPwordTemp = studentPword;
  },
  UPDATE_LIC_EXP: (state, { studentLicExp }) => {
    state.studentProfile.lic_exp = studentLicExp
  },
  // updateField,
  CLEAR_STUDENT: (state) => {
    state.studentProfile = null;
    state.studentProfile = initialState.studentProfile;
    state.studentMessage = "";
    state.studentTranscript = "";
    state.studentPwordTemp = "";
  },
  CLEAR_MESSAGE: (state) => {
    state.studentMessage = null;
  }
};

const actions = {
  STUDENT_RESET: ({ commit }) => {
    commit('CLEAR_STUDENT');
  },

  MESSAGE_RESET: ({ commit }) => {
    commit('CLEAR_MESSAGE');
  },

  INITIALIZE_STUDENT_PROFILE: ({ commit, state }, { studentStart }) => {
    // Reset studentProfile
    //    commit('UPDATE_STUDENT_PROFILE', {studentProfile: this.studentStructure});
    console.log("Starting student with " + JSON.stringify(studentStart));
    commit('UPDATE_STUDENT_PROFILE', { studentProfile: studentStart });
  },
  LOAD_STUDENT_PROFILE: async ({ commit, state }, { student, basic }) => {
    let studentProfile = {}
    console.log('Before UPDATE_STUDENT_PROFILE reseller and studentPid found ' + student.reseller + '/' + student.studentPid);
    if (student.studentPid != 0) {
      studentProfile = await getStudentProfile(student)
    }
    else {
      studentProfile = student
    }
    await commit('UPDATE_STUDENT_PROFILE', { studentProfile: studentProfile });
  },
  UPDATE_LOCAL_STUDENT: ({ commit, state }, { studentProfile }) => {
    commit('UPDATE_STUDENT_PROFILE', { studentProfile: studentProfile });
  },
  UPDATE_MASTER_STUDENT: ({ commit, state }, { studentProfile }) => {
    console.log('Sending UPDATE to home', JSON.stringify(studentProfile));
    let student_uuid = studentProfile.student_uuid;
  },
  SetLicExp: ({ commit, state}, {studentLicExp}) => {
    console.log(`Setting LicExp ${studentLicExp}`)
    commit('UPDATE_LIC_EXP', studentLicExp )
  },
  // ADD_NEW_STUDENT: ({ state, commit, dispatch, rootState, rootGetters }, { studentProfile }) => {
  ADD_NEW_STUDENT: async ({ commit }, { studentProfile }) => {
    let studentPword = studentProfile.password
    console.log(`ADD_NEW_STUDENT with ${JSON.stringify(studentProfile)}`)
    let student = {}
    return student = await addNewStudent(studentProfile)
      .then((student) => {
        console.log(`UpdateStudent resp return ${JSON.stringify(student)}`)
        if (student.msg !== 'Success') {
          // Return to the add student page with copy of the detail

          const studentMessage = { msg: 'Err', detail: student.profile };
          console.log('StudentErr', studentMessage)
          commit('UPDATE_STUDENT_MESSAGE', { studentMessage: studentMessage });
        } else {
          const studentMessage = { msg: 'Success', detail: `Student ID is ${student.profile.username}`};
          commit('UPDATE_STUDENT_MESSAGE', { studentMessage });
          commit('UPDATE_STUDENT_PROFILE', { studentProfile: student.profile });
          commit('UPDATE_TEMP_PW', { studentPword })
        }
      })
      .catch((err) => {
        console.log(err)
        const studentMessage = { msg: 'Failed', detail: err }
        commit('UPDATE_STUDENT_MESSAGE', { studentMessage })
      })
  },
  SAVE_STUDENT_UPDATE: ({ commit, state }, { studentProfile }) => {
    let student_uuid = studentProfile.uuid;
    // Clear the message
    commit('CLEAR_MESSAGE');
    // First get the auth token from server
    axios.post('/prou_api/v1/profiles/user/token', { headers: { 'Content-Type': 'application/json', } })
      .then((response) => {
        var auth_token = response.data.token;
        console.log('Add post with ' + auth_token + ' to ' + state.studentProfile.schoolname);
        axios.put('/prou_api/v1/reseller_student/students/' + state.studentProfile.uuid, { headers: { 'Content-Type': 'application/json', 'X-Csrf-token': auth_token }, data: state.studentProfile })
          .then((resp) => {
            if (resp.data.msg == 'Error') {
              // Return to the add student page with copy of the detail
              let studentMessage = { msg: 'Err', detail: resp.data };
              commit('UPDATE_STUDENT_MESSAGE', { studentMessage: studentMessage });
            } else {
              // Update profile with student array and send to the "student" component
              let studentMessage = { msg: 'Success', detail: resp.data.msg };
              commit('UPDATE_STUDENT_MESSAGE', { studentMessage: studentMessage });
              commit('UPDATE_STUDENT_PROFILE', { studentProfile: resp.data.student });
              // this.$router.push({ path: '/student' });
            }
          }, (err) => {
            console.log(err)
          })
      });
  },

  LOAD_STUDENT_TRANSCRIPT: (({ commit, state }, { studentPid }) => {
    console.log('LoadStudentTranscript ' + studentPid)
    if (studentPid) {
      let studentTranscript =  fetchStudentTranscript(studentPid)
      commit('UPDATE_STUDENT_TRANSCRIPT', { studentTranscript: studentTranscript.data.transcript })
    }
  })
};

const getters = {
  studentProfile: (state) => {
    return state.studentProfile;
  },
  studentTranscript: (state) => {
    return state.studentTranscript;
  },
  studentMessage: (state) => {
    return state.studentMessage;
  },
  studentPword: (state) => {
    return state.studentPwordTemp;
  },
  // getField,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
