<template>
  <div class="product">
    <reseller-header></reseller-header>
    <div v-if="orderStep === 3" class="product-detail">
      <product-detail></product-detail>
    </div>
    <div v-else-if="orderStep === 4" class="general-info">
      <general-info></general-info>
      <div class="row prod-bottom">
<!--
        <a class="btn btn-alert"><button class="btn btn-small btn-normal" @click="detailReturn">
          Back to Product Details
        </button></a>
-->
      </div>
    </div>
    <div v-else-if="orderStep === 4.5" class="package-items">
      <div class="order-info">
<div class="order-summary-block">
        <h1 class="panel-title">Order Summary</h1>
        <order-summary></order-summary>
</div>
      </div>
      <package-items></package-items>
      <div class="row prod-bottom">
<!--
        <a class="btn btn-alert"><button class="btn btn-small btn-normal" @click="detailReturn">
          Back to Product Details
        </button></a>
-->
      </div>
    </div>
    <div v-else-if="orderStep === 5" class="order-summary">
      <header style="background: transparent;">
<div style="display: block; padding: 0 0 50px 0; max-width: 700px; margin: 0 auto;">
        <h1>Your Order</h1>
       <p class="order-text">
          You have selected the course or package listed below. Use the CONTINUE button to enter your student, billing and shipping information. If you wish to add courses to your order, use the ADD MORE button. If the order is not correct, use the START OVER button.
        </p>
</div>
      </header>
<div class="order-summary-block">
      <order-summary></order-summary>
</div>
    </div>
    <reseller-footer></reseller-footer>
  </div>
</template>

<script>
import ResellerHeader from "../components/ResellerHeader.vue";
import ProductDetail from "../components/ProductDetail.vue";
import PackageItems from "../components/PackageItems";
import ResellerFooter from "../components/ResellerFooter.vue";
import GeneralInfo from "../components/GeneralInfo.vue";
import OrderSummary from "../components/OrderSummary.vue";
import { mapGetters } from "vuex";

export default {
  name: 'Product',
  data() {
    return {
      currentStep: ''
    };
  },
  components: {
    ResellerHeader,
    ProductDetail,
    PackageItems,
    ResellerFooter,
    GeneralInfo,
    OrderSummary
  },
  mounted() {
    let orderStep = 3;
    this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
  },
  computed: {
    ...mapGetters({
      orderStep: 'orderStep'
    })
  },
  methods: {
    detailReturn() {
      const orderStep = 3;
      this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
      this.$store.dispatch("CourseSelectionClear")
    }
  }
};
</script>

<style lang="scss">

@import "../assets/styles/views/product.scss";

</style>
