<template>
<div class="general-info">
<!--<general-info-popup />-->
    <header class="gi-header">
      <h1>General Information For Your Order</h1>
      <h2>{{ school.schoolName }}</h2>
      <p class="contact">
        {{ school.schoolAddress.address1 }}
        <span v-if="school.schoolAddress.address2">
          {{ school.schoolAddress.address2 }}
          <br>
        </span>
        {{ school.schoolAddress.city }}, {{ school.schoolAddress.state }} {{ school.schoolAddress.postalCode }}
        <br>
        Web: {{ school.schoolUrl }}
        <br>
        Email: {{ school.schoolEmail }}
        <br>
        <span v-if="school.schoolCaBre">CalBRE Sponsor ID: {{ school.schoolCaBre }}</span>
      </p>
      <!--<p class="notice">Important Instructions: Please Read Carefully Before You Proceed</p>-->
      <p class="notice">Terms and Conditions for courses provided by this school are provided below. Please read and accept these terms to continue with your order.</p>
      <!--<p class="alert">If you understand the information stated herein, please select the "Accept Button" at the bottom of this page to continue your order. Thank you!</p>-->
    </header>
   <div class="accept-buttons">
      <div class="action-row">
        <button
            class="accept-button decline"
            @click="detailReturn"
            href="#">
          Decline
        </button>
      </div>
      <div class="action-row">
        <button
            class="accept-button"
            @click="goSummary"
            href="#">
          Accept
        </button>
      </div>
    </div>
    <div
      class="prod-content"
      v-html="productDisplay.course_type.general_info"
    ></div>
    <div class="accept-buttons">
      <div class="action-row">
        <button
            class="accept-button decline"
            @click="detailReturn"
            href="#">
          Decline
        </button>
      </div>
      <div class="action-row">
        <button
            class="accept-button"
            @click="goSummary"
            href="#">
          Accept
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GeneralInfoPopup from "@/components/Order/GeneralInfoPopup.vue";

export default {
  name: 'GeneralInfo',
  data() {
    return {
      courseSelect: false,
      openInfo: false,
    };
  },
  components: {
    GeneralInfoPopup,
  },
  computed: {
    ...mapGetters({
      school: 'resellerProfile',
      productDisplay: 'productDisplay',
      orderStatus: 'orderDetail',
      orderStep: 'orderStep',
      courseOptionType: 'courseOptionType',
    }),
    productType() {
      return this.productDisplay.product_type;
    },
    itemCount() {
      return this.orderStatus.line_item.length;
    },
    courseCount() {
      return this.orderStatus.course_details.length;
    },
  },
  methods: {
    LicenseFooter() {
      let lf = '';
      if (this.resellerProfile.school.schoolCaBre) {
        lf =
          `CalBRE Sponsor ID ${this.resellerProfile.school.schoolCaBre}, `;
      }
      if (this.resellerProfile.school.schoolCE) {
        lf = `${lf}CE#${this.resellerProfile.school.schoolCE}, `;
      }
      if (this.resellerProfile.school.schoolNmls) {
        lf = `${lf}NMLS#${this.resellerProfile.school.schoolNmls}`;
      }
      return lf;
    },
    detailReturn() {
      const orderStep = 1;
      this.$store.dispatch('LOAD_ORDER_STEP', { orderStep });
      this.$store.dispatch('ORDER_RESET');
      this.$router.push({ path: '/reseller' });
    },
    goSummary() {
      // eslint-disable-next-line prefer-destructuring
      let orderStep = 5;
      console.log(`THe OrderStep is ${orderStep}`);
      this.$store.dispatch('LOAD_ORDER_STEP', { orderStep });
    },
  },
};
</script>

<style lang="scss">

@import "../assets/styles/components/generalinfo.scss";

</style>
