<template>
	<div class="student-summary">
    <h4 class="s-title">Student Information: </h4>
    <div class="s-label">Student:</div><div class="s-field">{{ studentProfile.fullname }}</div>
    <div class="s-label">PIN:</div><div class="s-field">{{ studentProfile.username }}</div>
    <div class="s-label">Address:</div><div class="s-field">{{ studentProfile.address }}<br>
      <div v-if="studentProfile.address2 != null">
        {{ studentProfile.address2 }}
      </div>
    {{ studentProfile.city }}, {{ studentProfile.state }} {{ studentProfile.postal_code }}
    </div>
    <div class="s-label">Phone:</div><div class="s-field">{{ studentProfile.phone }}</div>
    <div class="s-label">Fax:</div><div class="s-field">{{ studentProfile.fax }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  name: 'StudentSummary',

  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      school: "school",
      studentProfile: 'studentProfile',
      studentPword: 'studentPword',
    })
  },
}
</script>

<style lang="scss">

.student-summary {
  margin-top: 15px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  .s-title {
    font-size: 1.1em;
    font-weight: 600;
    padding-bottom: 10px;
    width: 100%;
    display: block;
  }
  .s-label {
    display: block;
    width: 25%;
  }
  .s-field {
    display: inline-block;
    width: 70%;
  }
}
</style>