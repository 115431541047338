<template>
  <div class="home">
    <div class="header">
      <div class="logo">
        <img class="home-logo" src="https://www.prou.net/sites/default/files/logo/prou-logo.svg" />
      </div>
    </div>
    <state-reseller></state-reseller>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import StateReseller from '../components/StateReseller.vue';

export default {
  name: 'home',
  components: {
    StateReseller,
  },
  computed: {
/*     loadingStatus () {
      return this.$store.getters.loadingStatus;
    } */
  },
  mounted() {
    let orderStep = 0;
    this.$store.dispatch('LOAD_ORDER_STEP', { orderStep });
    this.$store.dispatch('SET_SUBMITTED_STATUS', { submitStatus: false });
    this.$store.dispatch('SET_ISVALID_STATUS', { valStatus: true });
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/views/home.scss";
</style>
