<template>
   <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <slot name="main" class="main-slot">

          </slot>
        </div>
        <div class="modal-footer">
            <slot name="footer">
            </slot>
          </div>
      </div>
    </div>
  </transition>
</template>

<script>

  export default {

    methods: {
      confirm() {
        this.$emit('close');
        this.$router.push('/enroll/confirm');
      },
      continueOrder() {
        this.$emit('close');
      }
    }
  }
</script>

<style>

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: block;
    width: 100%;
    height: auto;
  }

  .modal-container {
    max-width: 70%;
    height: auto;
    margin: 0px auto;
    /* padding: 20px 30px; */
    background-color: transparent;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-default-button {
    float: right;
  }

  .modal-footer {
    border-top: 0px;
    text-align: center;
    width: 60%;
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-footer .modal-default-button {
    float: none;
  }

</style>