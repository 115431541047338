import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Reseller from '../views/Reseller.vue';
import Product from '../views/Product.vue';
import Order from '../views/Order.vue';
import Completed from '../views/Completed.vue';
import Page404 from '../views/Page404.vue';
import store from '../store'

Vue.use(Router);

 const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/reseller',
    name: 'reseller',
    component: Reseller,
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
    props: true,
  },
  {
    path: '/order',
    name: 'order',
    component: Order,
  },
  {
    path: '/completed',
    name: 'completed',
    component: Completed,
  },
 {
     path: '*',
     name: 'not-found',
     component: Page404,
 },
]

const index = new Router({
  mode: 'history',
  routes
})

index.beforeEach((to, from, next) => {
  console.log(`Router test before ${store.state.orderStep}`)
  if (store.state.orderStep === null) {
    let orderStep = 0
    store.dispatch("LOAD_ORDER_STEP", { orderStep });
    next({ name: "home" })
  } else {
    next()
  }
})

export default index
