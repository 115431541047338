import Vue from 'vue'
import BootstrapVue from "bootstrap-vue";
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap/dist/css/bootstrap.css'

import Vuelidate from "vuelidate"
import Cleave from "vue-cleave-component"
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon.js'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vue2Filters from 'vue2-filters';
import VueJSModal from "vue-js-modal";
import VueYouTubeEmbedded from 'vue-youtube-embed'
import './assets/styles/styles.css'
import './assets/styles/_global.scss'

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueJSModal)
Vue.use(VueYouTubeEmbedded)
Vue.use(Vue2Filters)
Vue.use(Cleave)

Vue.component('v-icon', Icon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
