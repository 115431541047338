<template>
  <div class="app-base">
      <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style lang="scss">
@import "./assets/styles/styles.css";
</style>
