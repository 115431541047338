<template>
  <div class="package-items-select content">
<!--    <pre>
      {{ package_item }}
    </pre>-->
    <h3 class="selection-line" v-if="optionSelect !== '0'">
      Please select {{ optionSelect }} courses</h3>
    <div class="row">
      <div v-if="selectLeft > 0" class="select-less">
        <div v-if="selectLeft > 1">
          <p>Select {{ selectLeft }} more courses.</p>
        </div>
        <div v-else>
          <p>Select {{ selectLeft }} more course.</p>
        </div>
      </div>
      <div v-else class="select-comp select-done">
        <p>Done! Continue to the next step.</p>
      </div>
    </div>
    <div
        v-for="item_content in groupContent"
        :key="item_content.course_id"
        class="group-item-select content">
    <input
      type="checkbox"
      class="course-input"
      v-model="selectedCourses"
      :id="'course_' + item_content.course_id"
      :value="item_content"
      :disabled="selectLeft <= 0 && selectedCourses.indexOf(item_content) === -1"
      @click="checkSelect(item_content, $event)"
    /> <label>{{ item_content.course_sku }} {{ item_content.course_title }}</label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields';
import Integer from "vuelidate/lib/validators/integer";

export default {
  data() {
    return {
      selectedCourses: [],
      checked: false,
      disabled: null,
      course_count: null,
      groupContent: this.package_item.packageStructure[0],
    }
  },
  props: {
    package_item: [
      Object,
      Array],
    line_item: [String, Number],
  },
  methods: {
    checkSelect(course, event) {
      console.log(`On Check, we get ${JSON.stringify(course)}, and ${JSON.stringify(event)}`)
      var options = this.optionSelect
      if (event.target.checked) {
        var options_chosen = this.selectedCourses.length;
        var select_test = options - options_chosen
        console.log(`Test Status ${select_test} results from ${options_chosen} `);

        this.$store.commit('addCourseItem');
        this.course_count = (this.courseSelection.length > 0) ? this.courseSelection.length : 1;
        let course_count = this.course_count
        // console.log("This course count is", course_count);
        this.courseSelection[course_count - 1]['item_no'] = options_chosen;
        this.courseSelection[course_count - 1]['line_item_no'] = this.line_item;
        this.courseSelection[course_count - 1]['course_id'] = course.course_prod_id;
        this.courseSelection[course_count - 1]['course_sku'] = course.course_sku;
        this.courseSelection[course_count - 1]['course_title'] = course.course_title;

        let courseSelection = this.courseSelection;
        this.$store.dispatch('LOAD_COURSE_SELECTION', {courseSelection});
        // this.$store.commit('addCourseItem');

        // var item_count = this.orderDetail.line_item.length;
        var select_msg = "";
        var option_no = parseInt(options);
        //var options_chosen = this.selectedCourses.length;
        var pass = (options != options_chosen ? false : true);

        var option_test = options - option_no;
        console.log(`The test items are option_no ${option_no}, options_chosen ${options_chosen} resulting in ${option_test}`)

        if (!pass && (option_test < 0)) {
          select_msg = "This package has " + option_no + " courses required. Please select " + -option_test + " more courses.";
        } else if (!pass && (option_test > 0)) {
          select_msg = "Only " + option_no + " courses required. " + this.selectedCourses.length + " is too many for this package.";
        } else {
          select_msg = "Just right";
        }

        console.log("Select test: " + option_no + " With " + options_chosen + "( " + this.selectedCourses.length + " ) required means " + option_test + " " + select_msg);

        /*this.orderDetail['course_details'] = this.selectedCourses;
        var order = this.orderDetail;*/

        // console.log("Order in course select: " + JSON.stringify(order));
      } else {
        console.log("Unchecked course ", JSON.stringify(course));
        let sku = course.course_sku;
        console.log("Course Unchecked SKU ", sku);
        this.$store.dispatch('removeCourseItem', {sku});
      }

      //this.$store.dispatch('LOAD_STUDENT_ORDER', { order });

      //return this.activeProduct;

      return option_test;
    }
  },
  computed: {
    ...mapGetters({
      courseSelection: 'courseSelection',
    }),
    ...mapFields({
      courseItemNo: "orders.courseSelection.item_no",
      courseLineItemNo: "orders.courseSelection.line_item_no",
      courseId: "orders.courseSelection.course_id",
      courseSku: "orders.courseSelection.course_sku",
      courseTitle: "orders.courseSelection.course_title",
    }),
    ...mapMultiRowFields([
      'course_details'
    ]),
    optionSelect() {
      return this.package_item.groupSelection
    },
    selectLeft() {
      return this.optionSelect - this.selectedCourses.length
    }
  }
}
</script>

<style lang="scss">
@import "../assets/styles/components/packageitems.scss";

</style>
