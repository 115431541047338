import axios from 'axios';

// Basic URL for Drupal Store
const API_URL = process.env.VUE_APP_API_URL
// URL for the courses API interface
const RAPI_URL = process.env.VUE_APP_RAPI_URL
// PayPal URL
const PPAL_URL = process.env.VUE_APP_PPAL_URL

// const authBasic = 'Basic ' + btoa('prou_admin:PU_10machi');
const uName = process.env.VUE_APP_D7_USERNAME
const pW = process.env.VUE_APP_D7_PW
// const authBasic = btoa(`${uName}:${pW}`);

// const authBasic = 'Basic ' + btoa('prou_admin:PU_10machi');

export default axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const basicAxios = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  admin: {
    username: uName,
    password: pW
  }
});

export const rosterAxios = axios.create({
  baseURL: RAPI_URL,
  headers: {
    // 'Content-Type': 'application/json',
  },
});

export const ppalAxios = axios.create({
  baseURL: PPAL_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});
