<template>
  <div class="order-checkout">
    <v-app>
    <form class="checkout" id="ccForm">
      <div class="form-select">
        <div
          v-if="ordSubmit && valCheck.fullname == false "
          class="invalid-feedback"
        >Name on the Card is required.</div>
        <input
          placeholder="Name on Card"
          type="text"
          name="__Name"
          v-model="billingFullname"
          class="s-name"
          :class="{ 'is-invalid': (ordSubmit && valCheck.fullname) }"
          id="fullname" />

        <v-select
          :items="cardTypes"
          placeholder="Choose Card Type"
          v-model="checkout.cardType"
          class="card-type"
          outlined
          background-color="white"
        ></v-select>

        <div
          v-if="ordSubmit && valCheck.cc == false "
          class="invalid-feedback"
        >Credit Card number is required.</div>
        <cleave
          placeholder="Card Number"
          v-model="checkout.cardNumber"
          :options="cardOptions"
          class="s-ccno"
          name="cardNumber"
          :class="{ 'is-invalid': ordSubmit && valCheck.cc }"
          >
        </cleave>

        <div
          class="select-date"
          :class="{ 'is-invalid': ordSubmit &&  (valCheck.ccExp == false || valCheck.ccDate == false)}"
          >
          <p>Card Expiration</p>
            <div
              v-if="ordSubmit && valCheck.ccExp == false "
              class="invalid-feedback"
            >CC Expiration date is required.</div>
            <div
              v-if="ordSubmit && valCheck.ccExp == true && valCheck.ccDate == false "
              class="invalid-feedback"
            >CC has expired.</div>
          <v-select
            :items="cardMo"
            placeholder="Month"
            v-model="checkout.cardMo"
            class="card-mo"
            outlined
            background-color="white"
          ></v-select>
           /
          <v-select
            :items="cardYear"
            placeholder="Year"
            v-model="checkout.cardYear"
            class="card-yr"
            outlined
            background-color="white"
          ></v-select>
        </div>
        <div class="accept-buttons">
            <a class="accept-button confirm-order" @click="checkoutConfirm">Complete Registration</a>
          </div>
      </div>
    </form>
    </v-app>
    <div v-if="transResult.length > 0">
      <p>The result was  {{ transResult[0].auth }}  {{ transResult[0].status }} Maybe too much?</p>
      <pre>
        {{ transResult }}
      </pre>
    </div>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component';
import { mapGetters, mapState } from 'vuex';
import CheckOut from '../utils/checkoutVer.js';

export default {
  data() {
    return {
      // billingFullname: this.orderStatus.billing_fullname,
      cardTypes: [
        { value: "VISA", text: 'Visa' },
        { value: "MC", text: "MasterCard" }
      ],
      cardMo: [
        { value: "01", text: "January (1)" },
        { value: "02", text: "February (2)" },
        { value: "03", text: "March (3)" },
        { value: "04", text: "April (4)" },
        { value: "05", text: "May (5)" },
        { value: "06", text: "June (6)" },
        { value: "07", text: "July (7)" },
        { value: "08", text: "August (8)" },
        { value: "09", text: "September (9)" },
        { value: "10", text: "October (10)" },
        { value: "11", text: "November (11)" },
        { value: "12", text: "December (12)" },
      ],
      cardYear: this.cardYears(),
      cardNumber: null,
      cardOptions: {
        creditCard: true,
        delimiter: '-',
      },
      checkout: {
        // billingFullname: billingFullname,
        cardType: '',
        cardNumber: '',
        cardMo: '',
        cardYear: '',
      },
      valCheck: {
        fullname: true,
        cc: true,
        ccType: true,
        ccExp: true,
        ccDate: true,
      },
      ordSubmit: false,
      inValid: false,
      transaction: {},
    }
  },
  components: {
    Cleave,
  },
  computed: {
    ...mapGetters({
      orderDetail: "orderDetail",
      orderReturn: "orderReturn",
      transResult: "checkoutTrans",
    }),
    billingFullname() {
      return this.orderDetail.billing_info.billing_fullname;
    },
  },
  methods: {
    cardYears() {
      let d = new Date();
      let cy = d.getFullYear();
      let ey = cy + 10;
      let ccYear = [];
      for ( let i = cy; i <= ey; i++ ) {
        let j = i - 2000;
        let cobj = { value: j, text: i };
        ccYear.push(cobj);
      };
      return ccYear;
    },
    checkoutConfirm() {
      this.ordSubmit = true;
      this.inValid = false;

      // Validation of input:
      if (!this.orderDetail.billing_info.billing_fullname) {
        this.valCheck.fullname = false;
        this.inValid = true;
      } else {
        this.valCheck.fullname = true;
      }

      if (this.checkout.cardNumber == '') {
        this.valCheck.cc = false;
        this.inValid = true;
      } else {
        this.valCheck.cc = true;
      }

      if (this.checkout.cardMo == '' || this.checkout.cardYear == '') {
        this.valCheck.ccExp = false;
        this.inValid = true;
      } else {
        this.valCheck.ccExp = true;
      }

      var curDate = Date.now();
      var ccYear = 2000 + this.checkout.cardYear;
      var ccMonth = this.checkout.cardMo;
      var valDate = Date.UTC(ccYear, ccMonth);

      console.log(`CC Date Check with ${curDate} against ${valDate}: ${ccYear}:${ccMonth}`);
      if (valDate < curDate) {
        this.valCheck.ccDate = false;
        this.inValid = true;
      } else {
        this.valCheck.ccDate = true;
      }


      if (this.inValid === false) {

        let ccExp = this.checkout.cardMo + this.checkout.cardYear;

        // this.transResult = [];
        let nameParts = this.orderDetail.billing_info.billing_fullname.split(' ');
        let namePartsNo = nameParts.length;
        let nameLast = nameParts[namePartsNo - 1];
        let nameFirst = nameParts[0];

        this.transaction = {
          'order': {
            'amount': this.orderDetail.order_total.toFixed(2),
            'id': this.orderReturn.order_id
          },
          'billingInfo': {
            'studentPin': this.orderDetail.student_id,
            'email': this.orderDetail.billing_info.billing_email,
            'firstName': nameFirst,
            'lastName': nameLast,
            'street1': this.orderDetail.billing_info.billing_address,
            'city': this.orderDetail.billing_info.billing_city,
            'state': this.orderDetail.billing_info.billing_state,
            'zipcode': this.orderDetail.billing_info.billing_postal_code,
          },
          'paymentInfo': {
            'cardNumber': this.checkout.cardNumber,
            'expirationDate': ccExp
          }
        }
        console.log(`Submitting transaction ${JSON.stringify(this.transaction)}`);
        let transaction = this.transaction
        this.$store.dispatch('submitCheckout', { transaction });
        let orderStep = 8;
        this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
        this.$router.push('/completed');
      }
    },
  }
}
</script>


<style lang="scss">

@import "../assets/styles/components/ordercheckout.scss";

</style>
