<template>
  <div class="product-detail">
    <div v-if="loadingStatus === 'productDetail'" class="loading-status">
      <loading-info></loading-info>
    </div>
    <div v-if="loadingStatus === 'closed'">
      <section class="course-top">
        <div class="prod-overview">
          <div class="prod-image">
            <img :src="productDisplay.product_image">
          </div>
          <div class="prod-content">
            <div class="prod-header">
              <h1>{{ productDisplay.title }}</h1>
            </div>
            <div class="prod-ov">
              <span v-html="productDisplay.prod_descr1"></span>
            </div>

            <form class="prod-form" @submit.prevent="courseEnroll">
              <div class="prod-disc">
                <input
                  type="text"
                  class="discount-code"
                  v-model="discountCode"
                  placeholder="Discount Code"
                >
              </div>

              <div class="prod-price">${{ productDisplay.price_amount/100}}</div>
              <div class="prod-order">
                <button class="prod-button btn btn-warn" type="submit">Enroll Now</button>
              </div>
              <div class="prod-option" v-if="productDisplay.books_included !== 'none'">
                <p v-if="productDisplay.books_included === 'optional' && productDisplay.text_price !== 0">
                  <input type="checkbox" id="textbook" v-model="textbookInclude"> ADD PRINTED TEXTBOOKS $10
                  Physical textbooks may be ordered to supplement the online courses.
                  Shipping cost is $13.
                </p>
                <p v-if="productDisplay.books_included === 'included'">
                  Physical textbooks are included in this online course.
                  Shipping of ${{ textBookShipping }} will be added.
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="highlights"></section>
      <section class="course-lower">
        <div class="course-left">
          <p v-html="productDisplay.demo_info"></p>
        </div>
      </section>
    </div>

    <div class="active-modal">
      <modal-frame v-if="dialog === true" @close="dialog = false" scrollable="false">
        <div slot="main">
          <youtube
            :video-id="videoId"
            player-width="100%"
            player-height="500px"
            ></youtube>
          <v-btn
            color="orange darket-1"
            flat
            outline
            @click="dialog = false"
          >
            Close
          </v-btn>
        </div>
      </modal-frame>
    </div>
      <!-- <div class="package-courses">
        <div v-if="productDisplay.prod_type == 'packages'">
          <package-items></package-items>
        </div>
      </div>
        <div class="product-descr" v-if="productDisplay.prod_descr3">
          <span v-html="productDisplay.prod_descr3"></span>
        </div>
        <div class="demo-link"></div>
      </div> -->
  </div>
</template>

<script>
import ModalFrame from './ModalFrame';
import LoadingInfo from './LoadingInfo';
import { mapGetters, mapState } from "vuex";
import { mapFields } from 'vuex-map-fields';
import { getIdFromURL } from 'vue-youtube-embed';

// import PackageItems from './PackageItems.vue';

export default {
  data() {
    return {
      bannerUrl:
        "http://test-prou-net.pantheonsite.io/sites/default/files/school/prou_admin/images/",
      errors: [],
      // textbook: false,
      imageUrl: "http://dev-prou-net.pantheonsite.io/sites/default/files/",
      defaultProdImage: "PROU_ProdImageDefaultCA_transparent.png",
      defaultDemoImage: "PROU_DemoDefault_transparent.png",
      discountCode: "",
      baseShipping: 13,
      dialog: false,
      player: "",
      // orderStep: this.orderStep,
      // videoId: '',
    };
  },
  components: {
    ModalFrame,
    LoadingInfo,
    // PackageItems
  },
  methods: {
    courseEnroll() {
      console.log("Enroll into ", this.productDisplay);
      let orderStep = this.orderStep
      const order = this.orderDetail;
      const price = this.productDisplay.price_amount;

      const item_count = order.line_item.length;
      order.line_item[item_count - 1].sku = this.productDisplay.sku;
      order.line_item[item_count - 1].item_no = item_count;
      order.line_item[item_count - 1].item_type = this.productDisplay.course_type.name;
      order.line_item[item_count - 1].item_title = this.productDisplay.title;
      order.line_item[item_count - 1].quantity = 1;
      order.line_item[item_count - 1].item_price = price;

      if (item_count > 1) {
        order.order_total = Number(order.order_total) + Number(price);
      }
      else {
        order.order_total = Number(price);
      }

      if (this.discountCode !== "") {
        order.line_item[item_count - 1].coupon_id = this.discountCode;
      }

      const textbook_include = {};

      if (this.textbookInclude) {
        let textbook_price = this.productDisplay.textbook_price;
        if (this.productDisplay.product_type === 'Package') {
          textbook_include.sku = `${this.productDisplay.sku} - TEXTS`;
          textbook_include.title = `Textbook(s) for ${this.productDisplay.title}`;
          textbook_include.price = textbook_price;
        } else {
          textbook_include.sku = this.productDisplay.textbook_info.textbook_sku;
          textbook_include.title = this.productDisplay.textbook_info.textbook_title;
          if (textbook_price && textbook_price > 0) {
            textbook_include.price = textbook_price;
          } else {
            textbook_include.price = this.productDisplay.textbook_info.textbook_price;
          }
        }
        console.log(`Textbook entry is ${JSON.stringify(textbook_include)} with price ${textbook_include.price}`);
        order.line_item[item_count - 1].textbook_include = textbook_include;
        order.order_total = Number(order.order_total) + Number(textbook_price);
      }

      if (this.productDisplay.orientation) {
        order.line_item[item_count - 1].orientation = this.productDisplay.orientation;
      } else {
        order.line_item[item_count - 1].orientation = false;
      }

      order.reseller_id = this.resellerName;

      let packageId = this.productDisplay.sku
      this.$store.dispatch('LOAD_PACKAGE_COURSES', { packageId: packageId })

      this.$store.dispatch("LOAD_STUDENT_ORDER", { order });
      // let orderStep = 4;
      // Update to bypass GeneralInfo go directly to course selection //
      const itemCount = this.itemCount;
      const item_no = this.orderDetail.line_item[itemCount - 1].item_no;
      if (this.productType === 'Package') {
        if (this.courseOptionType !== 0) {
          orderStep = 4.5;
          const loadingStatus = 'packageItems';
          this.$store.dispatch('LOAD_ORDER_STEP', {orderStep})
          this.$store.dispatch('SET_LOADING_STATUS', {loadingStatus});
        } else {
          orderStep = 4;
        }
      } else if (this.productType === 'Course') {

        const CCId = (this.courseCount > 0) ? this.courseCount - 1 : 0;
        // console.log(`Course Count = ${this.courseCount} / CCId = ${CCId}`);
        orderStep = 4;
        console.log('inside ProductType Course with orderStep', orderStep);
        this.orderDetail.course_details[CCId] = {
          line_item_no: item_no,
          course_id: this.productDisplay.course,
          course_sku: this.productDisplay.sku,
          course_title: this.productDisplay.course_description,
        };
        const courseSelection = this.orderDetail.course_details;
        this.$store.dispatch('LOAD_COURSE_SELECTION', { courseSelection });
      } else {
        // assumes only required courses which all have to be put in the order course object
        orderStep = 4;
        const packageId = this.productDisplay.sku;
        this.$store.dispatch('LOAD_PACKAGE_COURSES', { packageId });

      }
      // console.log(`THe OrderStep is ${orderStep}`);

      this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
    },
    returnToProducts() {
      let orderStep = 2;
      this.$store.dispatch('LOAD_ORDER_STEP', { orderStep });
      this.$store.dispatch('RESET_PRODUCT_DISPLAY');
      this.$router.push({ path: '/reseller' });
    },
    playerReady(player) {
      this.player = this.$refs.youtube;
    },

    playerClose() {
      this.player = player;
      this.player.stopVideo();
      this.dialog = false;
    }
  },
  computed: {
    ...mapState(['productDisplay']),
    ...mapGetters([
      'productDisplay',
      'orderDetail',
      'resellerName',
      'orderStep',
      'loadingStatus',
      'courseOptionType',
    ]),
    ...mapFields({
      textbookInclude: 'orders.orderStatus.textbook_include'
    }),
    productType() {
      return this.productDisplay.product_type;
    },
    itemCount() {
      return this.orderDetail.line_item.length;
    },
    textBookShipping() {
      if (this.productDisplay.text_incl == 'Included' && this.productDisplay.text_price != 0) {
        this.baseShipping = this.productDisplay.text_price/100;
      }
      return this.baseShipping;
    },
    demoImageFile() {
      var demoImageSrc = this.imageUrl;
      if (!this.productDisplay.demo_image) {
        demoImageSrc = demoImageSrc + this.defaultDemoImage;
      } else {
        demoImageSrc = demoImageSrc + this.productDisplay.demo_image;
      }
      return demoImageSrc;
    },
    videoId() {
      return this.$youtube.getIdFromURL(this.productDisplay.demo_link);
    }

  },
  mounted() {
    if (this.resellerName === "") {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss">
@import "../assets/styles/components/productdetail.scss";
.v-btn {
  background-color: lightgray;
}
</style>
