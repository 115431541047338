import { basicAxios } from './vue-axios/axios';
import { authToken } from "@/utils/commonService.js";
import axios from "@/utils/vue-axios";

// let studentReturn = [];
const uName = process.env.VUE_APP_D7_USERNAME
const pW = process.env.VUE_APP_D7_PW
const authBasic = btoa(`${uName}:${pW}`);


// eslint-disable-next-line camelcase
// const authToken = authToken()

export const createNewOrder = async (order) => {
  let orderReturn = []
  return await basicAxios
    .post('/prou_api/v1/prou_orders/order_details/', {
      headers: {
        'Content-Type': 'application/json',
        'X-Csrf-token': authToken,
        'Authorization': authBasic
      },
      data: order
    })
      .then((resp) => {
        `Response in OrderSevice ${JSON.stringify(resp)}`
        if (resp.status !== 200) {
          // Return to the add student page with copy of the detail
          orderReturn = {
            status: 400,
            msg: 'Err',
            detail: resp.data.msg,
            orderResp: resp.data.order };
        } else {
          `Response in OrderSevice ${JSON.stringify(resp)}`
          // Update profile with student array and send to the "student" component
          orderReturn = {
            status: 200,
            msg: 'Success',
            resp: resp,
            detail: resp.data,
            orderResp: resp.data.orderSummary,
            lineItems: resp.data.lineItems
          };
        }
        // console.log(`Order Detail in orderService ${JSON.stringify(orderReturn.detail)}`)
        return orderReturn;
      }, (err) => {
        console.log('CreateNewOrder: order_details err: ', err);
        return orderReturn = {
          status: 400,
          error: err
        }
      })
}

export const submitOrderCheckout = async (order) => {
  return await axios
    .post('/prou_api/v1/prou_orders/order_details/', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authBasic
      },
      data: order
    })
    .then((response) => {

      commit('UPDATE_ORDER_RESPONSE', {
        orderResponse:
          {
            orderDetails: response.data.order,
            lineItems: response.data.lineItems
          }
      });
    })
    .catch((err) => {
      console.log('submitOrder Error ', err);
      let orderMessage = {
        status: 'Server Error',
        errStatus: err.status,
        errMessage: 'System Error, cannot process this order at this time'
      }
      commit('SET_ORDER_MESSAGE', { orderMessage: orderMessage })

    });
}

export const submitTransaction = async (transaction) => {
  let transFull = {}
  // need to indicate transaction to be processed by PPFlow
  transaction.billing_route = 1

  return await basicAxios
    .post('/prou_api/v1/prou_orders/order_payment', {
      headers: {
        'Content-Type': 'application/json',
        'X-Csrf-token': authToken,
        'Authorization': authBasic
      },
      data: transaction
    })
    .then((response) => {
      return transFull = response.data;
    })
    .catch((err) => {
      console.log('submitOrder Error ', err)
      return transFull = {
        status: 400,
        error: err
      }
    })
}

export const fetchSingleOrder = async (orderId, resellerId) => {
  return await basicAxios
    .get(
      `prou_api/v1/prou_orders/order_details/${orderId}?reseller_name=${resellerId}`,
      {
        headers: { 'Authorization': authBasic }
      },
    )
    .then((resp) => {
      return resp
    })
    .catch((err) => {
      console.log(`Fetch Order error ${err}`)
    })
}
