module.exports = {
  schoolStructure: {
    name: '',
    uid: '',
    pid: '',
    schoolName: '',
    schoolAddress: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US',
    },
    schoolBanner: '',
    schoolDesc: '',
    schoolNmls: '',
    schoolCaBre: '',
    schoolCE: '',
    schoolFooterTag: '',
  },
};
