import { basicAxios } from './vue-axios/axios.js'
// import axios from "@/utils/vue-axios";
// import axios from "@/utils/vue-axios"

// const authBasic = `Basic ${btoa(process.env.D7_BASIC_ACCT)}`

export const fetchReseller = async (resellerName) => {
  let school = {}
  return await basicAxios.get(`/prou_api/v1/schools/reseller_profile/${resellerName}`)
    .then((response) => {
      // console.log(`Response to LOAD_RESELLER ${JSON.stringify(response)}`)
      // commit('UPDATE_RESELLER_PROFILE', {reseller: response.data.school})
      return school = response.data.school
    }, (err) => {
      console.log(err)
      return school = {
        status: 400,
        error: err
      }
    })
}

export const fetchResellerList = async () => {
  let resellerList = {}
  return await basicAxios.get('/prou_api/v1/schools/schools')
    .then((response) => {
      return resellerList = response.data
      // commit('UPDATE_RESELLER_LIST', { resellerList: response.data })
    }, (err) => {
      console.log(err)
      return resellerList = {
        status: 400,
        error: err
      }
    })
}

export const fetchResellerStateList = async (usState) => {
  let stateList = {}
  return await basicAxios.get(`/prou_api/v1/schools/schools/?state=${usState}`)
    .then((response) => {
      return stateList = response.data
    }, (err) => {
      console.log(err)
      return stateList = {
        status: 400,
        error: err
      }
    })
}

export const fetchResellerProdDisplay = async (resellerName, productSku) => {

  let resellerProdDisp = {}
  return await basicAxios
    .get('prou_api/v1/prou_products/product_display/' + resellerName + '/' + productSku)
    .then((response) => {
      return resellerProdDisp = response.data.data
    })
    .catch((err) => {
      return resellerProdDisp = {
        status: 400,
        error: err
      }
    })
}


/*export default {
  fetchReseller,
  fetchResellerList,
  fetchResellerStateList
}*/
