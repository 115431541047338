<template>
  <div class="row course-list">
    <div class="body-title"><h1>Package Orders</h1></div>
    <div v-if="loadingStatus === 'packageItems'" class="loading-status">
      <loading-info></loading-info>
    </div>
    <div v-if="loadingStatus ==='closed'" class="package-content">
      <div class="row body-list">
        <div
        class="content p-course"
        v-for="group in packageGroups.packageContent"
        :key="group.groupId">
            <!-- if not 0 (required) use check boxes -->
            <div v-if="group.groupOption !== '0'" class="row p-select">
              <h4 class="p-item-title"> <span v-if="groupNo > 2">{{ groupNo - 1}}</span></h4>
              <package-items-select :package_item="group" :line_item="line_item_no"></package-items-select>
            </div>
            <div v-else class="p-include row">
              <h4 class="p-item-title">Included Courses</h4>
            <!-- if 0 (required) use hidden fields -->
              <div
                  class="p-item-content"
                  v-for="item_content in group.packageStructure[0]"
                  :key="item_content.id">
                <label>{{ item_content.course_sku }} {{ item_content.course_title }}</label>
              </div>
            </div>
        </div>
      </div>
      <div class="row accept-buttons">
        <div class="action-row">
          <button
              class="accept-button"
              :disabled="disabled"
              @click="goSummary" href="#">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import { Validator } from 'vee-validate';
import { mapGetters } from 'vuex';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields';
import LoadingInfo from './LoadingInfo';
import PackageItemsSelect from './PackageItemsSelect.vue'


export default {
  data() {
    return {
      selectedCourses: [],
      // courseSelection: [],
      checked: false,
      // disabled: true,
      course_count: 1,
      line_item_no: 1,
      orderStep: 4,
      // groupContent: this.packageContent[0],
    }
  },
  methods: {
    goSummary() {
      let orderStep = this.orderStep
      this.courseItems.packageContent.forEach((item) => {

        if (parseInt(item.groupSelection) == 0) {
          const groupCourses = item.packageStructure[0];
          console.log('requiredCourses = 0', JSON.stringify(groupCourses))

          return groupCourses.forEach((course) => {
            this.$store.commit('addCourseItem');
            this.course_count = (this.courseSelection.length > 0) ? this.courseSelection.length : 1;
            let course_count = this.course_count
            console.log("This course count is", course_count);
            // this.courseSelection[course_count - 1]['item_no'] = options_chosen;
            this.courseSelection[course_count - 1]['line_item_no'] = this.line_item;
            this.courseSelection[course_count - 1]['course_id'] = course.course_prod_id;
            this.courseSelection[course_count - 1]['course_sku'] = course.course_sku;
            this.courseSelection[course_count - 1]['course_title'] = course.course_title;
          });
          let courseSelection = this.courseSelection;
          console.log("Required courseSelection ", JSON.stringify(courseSelection));
          this.$store.dispatch('LOAD_COURSE_SELECTION', {courseSelection});
        }
      })
      orderStep = 4;
      this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
    }
  },
  created() {

  },
  components: {
    LoadingInfo,
    PackageItemsSelect
  },
  async mounted() {
    console.log("PackageItems product_sku " + this.product_sku);
    let packageId = this.product_sku;
    // let basic = this.basic;
    // await this.$store.dispatch('LOAD_PACKAGE_COURSES', { packageId: packageId });
    await this.$store.dispatch('LOAD_PACKAGE_COURSES', { packageId: packageId})
    await this.$store.dispatch('UNSET_LOADING_STATUS')

    // let noGroups = this.packageContent.groupNo
    let packageGroups = this.packageContent
    let testGroup = [];
    for (let i = 0; i < groupNo; i++) {
      let item_content = []
      testGroup = packageGroups[i]
      console.log(`loaded test group ${JSON.stringify(testGroup)}`)
      if (testGroup.option_id == 0) {
        item_content = packageGroups[i].item_content;
        let itemNo = item_content.length
        let includedCourses = [];
        for (let j = 1; j < itemNo + 1; j++){
          let courseSelection = item_content[j - 1]
          courseSelection['item_no'] = j
          courseSelection['line_item_no'] = this.line_item_no
          includedCourses.push(courseSelection);
        }
        await this.$store.dispatch('LOAD_COURSE_SELECTION', { 'courseSelection': includedCourses });
      }
    }
    /* if (this.courseItems.option_select == "0") {
      let course_count = this.courseItems.length;
      console.log("PackageItems course_count ", course_count);
      this.courseItems.forEach(function(item){
        this.selectedCourses.push(item.course_sku);
      });
    } */
  },

  computed: {
    ...mapGetters({
      courseItems: 'packageCourses',
      basic: 'basic',
      orderDetail: 'orderDetail',
      option_select: 'courseOptionType',
      courseSelection: 'courseSelection',
      product_sku: 'productSku',
      loadingStatus: 'loadingStatus',
    }),
    notEqual() {
      console.log("PackageGroups notEqual ", this.packageGroups.groupSelection);
      return this.courseSelection.length - this.packageGroups.groupSelection;
    },
    disabled() {
      if (this.notEqual < 0) {
        return true;
      } else {
        return false;
      };
    },
    ...mapFields({
      courseItemNo: "orders.courseSelection.item_no",
      courseLineItemNo: "orders.courseSelection.line_item_no",
      courseId: "orders.courseSelection.course_id",
      courseSku: "orders.courseSelection.course_sku",
      courseTitle: "orders.courseSelection.course_title",
    }),
/*
    ...mapMultiRowFields([
      'course_details'
    ]),
*/

    packageGroups() {
      return this.courseItems
    },
    groupNo() {
      return this.courseItems.packageContent.length
    },
    requiredCourses() {
      return this.courseItems.packageContent.forEach((item) => {

        if (parseInt(item.groupSelection) == 0) {
          console.log('requiredCourses = 0', JSON.stringify(item.packageStructure[0]))
          return item.packageStructure[0];
        }
      })
    }
   /* packageContent() {
      return this.courseGroups.packageStructure[0]
    }*/
  }
}

</script>

<style lang="scss">
@import "../assets/styles/components/packageitems.scss";
.p-include {
  padding: 20px;
}
</style>
