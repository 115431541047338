<template>
  <div class="product-type-list">
    <header>
      <h2>Step 1</h2>
      <h3>Choose your prefered course type</h3>
    </header>
    <div v-if="loadingStatus === 'productListType'" class="loading-status">
      <loading-info></loading-info>
    </div>
    <div v-if="loadingStatus ==='closed'">
      <form>
      <v-container fluid class="step1-list">
        <v-radio-group v-model="prod_type" column>
          <div v-for="type in baseTypes" :key="type.type_id" class="row p-types">
            <v-radio
            :label="type.type_name"
            :value="type.type_id"
            @change="setActiveList(type)">
            </v-radio>
          </div>
        </v-radio-group>
      </v-container>
      </form>
    </div>
  </div>
</template>

<script>
import PackageItems from "./PackageItems.vue";
import LoadingInfo from "./LoadingInfo";
import { mapGetters } from "vuex";

export default {
  name: "ProductTypeList",
  data() {
    return {
      activeList: "",
      activeType: "",
      product_sku: "",
      prod_ship: "",
      showRaw: false,
      activeProduct: "",
      resellerProduct: [],
      prod_type: [],
      prod: [],
    };
  },
  components: {
    LoadingInfo,
    PackageItems,
  },
  computed: {
    ...mapGetters({
      resellerProducts: "resellerProducts",
      courseItems: "packageCourses",
      productDetail: "productDetail",
      reseller: "resellerName",
      orderDetail: "orderDetail",
      loadingStatus: "loadingStatus",
      basic: "basic",
    }),
    baseTypes() {
      let types = this.resellerProducts.prod_types;
      return types.filter(t => {
        return t.type_id != '13';
      })
    }
  },
  methods: {
    setActiveList(type) {
      let activeType = type.type_id;
      console.log(`Type passed to setActiveList is ${JSON.stringify(type)}`)
      console.log(`activeType set to ${activeType}`);
      this.$store.dispatch("LOAD_ACTIVE_TYPE", { activeType: type });

      const orderStep = 2;
      this.$store.dispatch("LOAD_ORDER_STEP", { orderStep });
    },
  }


}
</script>

<style lang="scss">

@import "../assets/styles/components/producttypelist.scss";

</style>
