import { rosterAxios } from './vue-axios/axios';
import { pfpConst } from '../data/payflowTransactionConst';

// const pfpAuth = `TENDER=C&PARTNER=${process.env.PFP_PARTNER}&USER=${process.env.PFP_MERCHANT}&PWD=${process.env.PFP_PW}`;
const pfpAuth = {
  TENDER: 'C',
  PARTNER: process.env.VUE_APP_PFP_PARTNER,
  USER: process.env.VUE_APP_PFP_MERCHANT,
  PWD: process.env.VUE_APP_PFP_PW
};

const authType = 'A';
const salesType = 'S';
const transConst = pfpConst;

const CheckOut = {

  getAuth(transaction) {
    // parse the transaction for to prepare the Payflow Pro transaction string
    console.log(`transaction is ${JSON.stringify(transaction)}`);
    let auth = 'success';
    let trans = {};
    let pfpOptions = [];


    let pfpMode = 'test';
    let nameParts = transaction.orderDetail.billing_fullname.split(' ');
    let namePartsNo = nameParts.length;
    let nameLast = nameParts[namePartsNo - 1];
    let nameFirst = nameParts[0];
    let ccExp = transaction.cardMo + transaction.cardYear;
   
    pfpOptions = {
      "mode": pfpMode,
      "partner": process.env.VUE_APP_PFP_PARTNER,
      'username': process.env.VUE_APP_PFP_MERCHANT,
      'vendor': process.env.VUE_APP_PFP_MERCHANT,
      'password': process.env.VUE_APP_PFP_PW,
      'type': 'C',
      'trxtype': salesType,
      'order': {
        'amount': transaction.orderDetail.order_total.toFixed(2),
        'id': transaction.orderDetail.order_id
      },
      'billingInfo': {
        'email': transaction.orderDetail.billing_email,
        'firstName': nameFirst,
        'lastName': nameLast,
        'street1': transaction.orderDetail.billing_address,
        'city': transaction.orderDetail.billing_city,
        'state': transaction.orderDetail.billing_state,
        'zipcode': transaction.orderDetail.billing_postal_code,
      },
      'paymentInfo': {
        'cardNumber': transaction.cardNumber,
        'expirationDate': ccExp
      }
    }

    // trans.push(transaction);
    // The URL we want to send looks like:
    // TRXTYPE=S&TENDER=C&USER=MerchantUserID&PWD=Pwd4Gateway&PARTNER=PayPal&ACCT=5105105105105100&EXPDATE=1215&AMT=23.45
    // &COMMENT1=Airport Shuttle&BILLTOFIRSTNAME=Jamie&BILLTOLASTNAME=Miller&BILLTOSTREET=123 Main St.&BILLTOCITY=San Jose
    // &BILLTOSTATE=CA&BILLTOZIP=951311234&BILLTOCOUNTRY=840&CVV2=123&CUSTIP=0.0.0.0
    // for testing use transCOst empty array
    // trans = trans.concat(pfpAuth);
    // trans.push(pfpAuth);
    // transaction.push(pfpAuth);
/*     JSON.parse(JSON.stringify(pfpAuth), (key, value) => {
      // console.log(`going through trans gets ${key} => ${value}`);
      // transPFP.concat('&', key, '=', value);
    }; */


    let transactionFull = `TRXTYPE=${salesType}`;
    JSON.parse(JSON.stringify(pfpAuth), (key, value) => {
      console.log(`PVP key is ${key} and value is ${value}`);
      if (key !== '') {
        transactionFull += `&${key}=${value}`;
      }
    });

    transactionFull += `&BILLTOFIRSTNAME=${nameFirst}&BILLTOLASTNAME=${nameLast}`;
    transactionFull += `&ACCT=${transaction.cardNumber}&EXPDATE=${transaction.cardMo}${transaction.cardYear}&AMT=${transaction.orderDetail.order_total}`;

/*     trans = rosterAxios
      .post('/pflow', {
        data: pfpOptions 
      })
      .then((result) => {
        console.log(`Return from PPal looks like ${result}`);
      })
      .catch((err) => {
        console.log(`Error from PPal ${err}`);
      }); */
    trans['status'] = 'success';
    trans['data'] = pfpOptions;
    /*  ORDERID: '',
    BILLTOFIRSTNAME: '',
    BILLTOLASTNAME: '',
    BILLTOSTREET: '',
    BILLTOSTREET2: '',
    BILLTOCITY: '',
    BILLTOSTATE: '',
    BILLTOZIP: '',
    BILLTOCOUNTRY: '' */

/*     trans.push({ 'options': pfpOptions });
    trans.push({ 'auth': auth }); */
    trans['options'] = pfpOptions;
    trans['auth'] = auth;
    console.log(`Transaction full is ${JSON.stringify(trans)}`);

    return trans;
  }
};

export default CheckOut;
